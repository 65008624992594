import React, { useState, useEffect } from "react";

import { auth } from "../../../stores/BaseStore";
import { useNavigate } from "react-router-dom";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { Box, Button, Link, TextField, Typography, Card } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../schemas/index";
import {
  ErrorTextSC,
  WrapperSC,
  ButtonContainerSC,
  ForgotPasswordContainerSC,
} from "./styled";

const Auth = view(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const navigate = useNavigate();
  const [authError, setAuthError] = useState("");
  const [unauthorized, setUnauthorized] = useState("");
  const onSubmit = async (data) => {
    await auth.login(data.email, data.password);
  };

  const logout = async () => {
    await auth.logout();
  };

  useEffect(() => {
    return () => {
      auth.errors = null;
    };
    // eslint-disable-next-line
  }, []);

  autoEffect(() => {
    auth.getUser();
    if (
      auth.isLoggedIn &&
      auth.user &&
      (auth.user.role_id === 2 || auth.user.role_id === 3)
    ) {
      logout();
      setUnauthorized("Niste autorizirani kao admin");
      navigate("/prijava");
    } else if (auth.isLoggedIn) {
      navigate("/backoffice/korisnici");
    }
  });

  autoEffect(() => {
    if (auth.errors?.message === "Request failed with status code 422") {
      setAuthError("Neispravan e-mail ili lozinka!");
    }
  });

  return (
    <WrapperSC>
      <Card
        style={{
          width: "100%",
          maxWidth: "600px",
          backgroundColor: "#FAFAFA",
        }}
      >
        <Box sx={{ m: 2 }}>
          <Typography
            color="textPrimary"
            sx={{
              fontSize: "30px",
              textAlign: "center",
              color: "#333444",
            }}
          >
            Prijava
          </Typography>
          <form>
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              id="email"
              name="email"
              type="text"
              variant="outlined"
              {...register("email")}
              onInput={() => {
                setAuthError("");
                setUnauthorized("");
              }}
            />
            <ErrorTextSC color="error">{errors.email?.message}</ErrorTextSC>
            <TextField
              fullWidth
              label="Lozinka"
              margin="normal"
              id="password"
              name="password"
              type="password"
              variant="outlined"
              {...register("password")}
              onInput={() => {
                setAuthError("");
                setUnauthorized("");
              }}
            />
            <ErrorTextSC color="error">
              {authError || unauthorized || errors.password?.message}
            </ErrorTextSC>
            <ButtonContainerSC>
              <Button
                onClick={handleSubmit(onSubmit)}
                style={{
                  backgroundColor: "#00BAFF",
                  maxWidth: "300px",
                }}
                fullWidth
                disabled={auth.isLoading ? true : false}
                size="large"
                type="submit"
                variant="contained"
              >
                {auth.isLoading ? "Učitavanje" : "Prijava"}
              </Button>
            </ButtonContainerSC>
          </form>

          <ForgotPasswordContainerSC>
            <Typography color="textSecondary" variant="body2">
              <Link
                href="/zaboravljenalozinka"
                variant="subtitle2"
                underline="hover"
                sx={{
                  cursor: "pointer",
                  marginTop: 1,
                }}
              >
                Zaboravili ste lozinku?
              </Link>
            </Typography>
          </ForgotPasswordContainerSC>
        </Box>
      </Card>
    </WrapperSC>
  );
});

export default Auth;
