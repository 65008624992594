import React, { useEffect, useState } from "react";

import { streets, cities } from "../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Button,
  Box,
  styled,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { useLocation } from "react-router-dom";
import Loader from "components/backoffice/utils/loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { streetsSchema } from "../schemas/index";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 600,
  bgcolor: "#f3f5f9",
  border: "1px solid #d3d3d3",
  borderRadius: 2,
};

const StreetDetailModal = view(({ id, open, handleClose, setSuccess }) => {
  const location = useLocation();

  const [edit, setEdit] = useState(false);
  const [counter, setCounter] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(streetsSchema) });

  const handleEdit = () => {
    setEdit((prevEdit) => !prevEdit);
  };

  const onSubmit = async (data) => {
    if (counter === 0) {
      handleEdit();
      setCounter(1);
    } else {
      await streets.editStreet(
        data.name,
        data.starting_number,
        data.ending_number,
        data.city_id,
        data.measurement_day,
        id
      );
      if (streets.success) {
        setSuccess(true);
        handleClose();
      }
      setCounter(0);
    }
  };

  autoEffect(async () => {
    if (!cities.cities) {
      await cities.getCities();
    }
  }, [location]);

  autoEffect(async () => {
    if (!streets.street) {
      await streets.getStreet(id);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      streets.street = null;
      cities.cities = null;
      streets.success = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      disableScrollLock
    >
      <Box sx={style}>
        {streets.street ? (
          <>
            <Box
              sx={{
                paddingTop: "12px",
                mx: 2,
                mb: 2,
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "1.25rem",
                  color: "#464C4C",
                  marginBottom: "10px",
                }}
              >
                Detalji ulice
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  color: "rgba(0, 0, 0, 0.54)",
                  marginBottom: "10px",
                }}
              >
                {streets.street.name}
              </Typography>
            </Box>

            <Box
              sx={{
                boxShadow: 1,
                mx: 2,
                mb: 2,
                height: "100%",
                overflow: "auto",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  mb: 2,
                }}
              >
                <Box sx={{ maxWidth: 600, flexGrow: 1, mx: 2 }}>
                  <form>
                    <fieldset style={{ border: "none" }} disabled={edit}>
                      <TextField
                        fullWidth
                        label="Naziv"
                        defaultValue={streets.street.name.toString()}
                        margin="normal"
                        id="name"
                        name="name"
                        type="text"
                        variant="outlined"
                        {...register("name")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.name?.message}
                      </Typography>
                      <TextField
                        fullWidth
                        label="Početni kućni broj"
                        defaultValue={streets.street.starting_number.toString()}
                        margin="normal"
                        id="starting_number"
                        name="starting_number"
                        type="text"
                        variant="outlined"
                        {...register("starting_number")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.starting_number?.message}
                      </Typography>
                      <TextField
                        fullWidth
                        label="Završni kućni broj"
                        defaultValue={streets.street.ending_number.toString()}
                        margin="normal"
                        id="ending_number"
                        name="ending_number"
                        type="text"
                        variant="outlined"
                        {...register("ending_number")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.ending_number?.message}
                      </Typography>

                      {cities.cities ? (
                        <>
                          <TextField
                            fullWidth
                            label="Grad"
                            margin="normal"
                            defaultValue={streets.street.City.id}
                            id="city_id"
                            name="city_id"
                            type="text"
                            variant="outlined"
                            {...register("city_id")}
                            select
                          >
                            <MenuItem value="" disabled>
                              Grad
                            </MenuItem>
                            {cities.cities.map((city) => (
                              <MenuItem value={city.id} key={city.id}>
                                {city.name}
                                {", "} {city.postal_code}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Typography
                            style={{
                              marginTop: "0",
                              marginBottom: "0",
                              marginRight: "14px",
                              marginLeft: "14px",
                              fontSize: "0.75rem",
                            }}
                            color="error"
                          >
                            {errors.city_id?.message}
                          </Typography>
                        </>
                      ) : (
                        <TextField
                          select
                          label="Grad"
                          margin="normal"
                          fullWidth
                        >
                          <MenuItem value="" disabled>
                            Morate dodati grad
                          </MenuItem>
                        </TextField>
                      )}
                      <TextField
                        fullWidth
                        type="number"
                        onKeyPress={(e) => {
                          !/[0-9]/.test(e.key) && e.preventDefault();
                        }}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 2);
                        }}
                        label="Dan mjerenja"
                        defaultValue={streets.street.measurement_day?.toString()}
                        margin="normal"
                        id="measurement_day"
                        name="measurement_day"
                        variant="outlined"
                        {...register("measurement_day")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.measurement_day?.message}
                      </Typography>
                    </fieldset>
                  </form>
                </Box>
              </Card>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                justifyContent: "flex-end",
                marginRight: "12px",
                marginBottom: "12px",
              }}
            >
              <Button
                onClick={handleSubmit(onSubmit)}
                style={{ backgroundColor: "#00BAFF" }}
                variant="contained"
              >
                {counter === 0 ? "Uredi" : "Spremi"}
              </Button>
              <Button
                onClick={handleClose}
                style={{ backgroundColor: "#f50057" }}
                variant="contained"
              >
                Odustani
              </Button>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </StyledModal>
  );
});

export default StreetDetailModal;
