import React from "react";
import { view } from "@risingstack/react-easy-state";
import Layout from "../../hoc/layout";
import NotificationsResult from "./notificationsResult";
import { notifications } from "../../../../stores/BaseStore";

const Notifications = view(() => {
  return (
    <Layout>
      <NotificationsResult notifications={notifications} />
    </Layout>
  );
});

export default Notifications;
