import { view } from "@risingstack/react-easy-state";
import { Button, Box, styled, Typography } from "@mui/material";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { notifications } from "../../../stores/BaseStore";
import { useNavigate } from "react-router-dom";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 400,
  bgcolor: "#f3f5f9",
  border: "1px solid #d3d3d3",
  borderRadius: 2,
};

const NotificationModal = view(({ open, handleClose, formData }) => {
  const navigate = useNavigate();

  const handlePost = async () => {
    await notifications.addNotification(
      formData.heading,
      formData.data,
      formData.streets,
      formData.broadcast
    );
    if (notifications.success) navigate("/backoffice/obavijesti");
  };

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
    >
      <Box sx={style}>
        <Box
          sx={{
            paddingTop: "12px",
          }}
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "1.25rem",
              color: "#464C4C",
              marginLeft: "16px",
              marginBottom: "10px",
            }}
          >
            Potvrda
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "1rem",
              color: "rgba(0, 0, 0, 0.54)",
              marginLeft: "16px",
              marginBottom: "10px",
            }}
          >
            Jeste li sigurni da želite poslati obavijest?
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            justifyContent: "flex-end",
            marginRight: "12px",
            marginBottom: "12px",
          }}
        >
          <Button
            onClick={() => {
              handlePost();
              handleClose();
            }}
            style={{ backgroundColor: "#00BAFF" }}
            variant="contained"
          >
            Da
          </Button>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "#f50057" }}
            variant="contained"
          >
            Ne
          </Button>
        </Box>
      </Box>
    </StyledModal>
  );
});

export default NotificationModal;
