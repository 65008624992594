import React, { useState, useEffect, useMemo } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useLocation } from "react-router-dom";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableContainer,
  Button,
} from "@mui/material";
import NotificationsToolbar from "./notificationsToolbar";
import { BoxSC } from "../../styled/styled";
import TableSortLabel from "../../utils/tableSortLabel";
import Loader from "components/backoffice/utils/loader";
import { sortArray } from "./sort";

const headers = [
  { name: "Naslov", field: "heading", sortable: true },
  { name: "Poruka", field: "data", sortable: true },
];

const NotificationsResult = view(({ notifications }) => {
  const location = useLocation();

  const [allNotifications, setAllNotifications] = useState(null);
  const [notificationId, setNotificationId] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);

  const [input, setInput] = useState("");

  const [clicked, setClicked] = useState(false);

  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const notificationsData = useMemo(() => {
    let computedNotifications = allNotifications;
    if (!computedNotifications) computedNotifications = [];

    if (input) {
      computedNotifications = computedNotifications.filter(
        (notification) =>
          notification.heading.toLowerCase().includes(input.toLowerCase()) ||
          notification.data.toLowerCase().includes(input.toLowerCase())
      );
    }
    if (computedNotifications) setTotalItems(computedNotifications.length);
    //Sorting comments
    if (sortingField) {
      sortArray(computedNotifications, sorting.field, sorting.order);
    }

    //Current Page slice
    return computedNotifications.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [allNotifications, page, rowsPerPage, input, sorting, sortingField]);

  const handleShow = (notification) => {
    setClicked(true);
    setNotificationId(notification.id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSortingChange = (field) => {
    const order = sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    setSorting({ field, order });
  };

  autoEffect(async () => {
    if (!notifications.notifications) {
      await notifications.getNotifications();
    }
  }, [location]);

  autoEffect(() => {
    if (notifications.notifications) {
      setAllNotifications(notifications.notifications);
    }
  }, [notifications.notifications]);

  useEffect(() => {
    return () => {
      notifications.notifications = null;
    };
    // eslint-disable-next-line
  }, []);

  if (notificationsData.length === 0 && !input) {
    return (
      <Box>
        <NotificationsToolbar
          input={input}
          setInput={setInput}
          disabled={true}
        />
        {notifications.isLoading ? (
          <Loader />
        ) : (
          <Typography
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            Nema niti jedne obavijesti
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <NotificationsToolbar
        input={input}
        setInput={setInput}
        disabled={false}
      />
      {notifications.notifications && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            overflow: "auto",
            mb: 2,
            mx: 2,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map(({ name, field, sortable }) => (
                    <TableCell
                      key={field}
                      onClick={() => (sortable ? onSortingChange(field) : null)}
                    >
                      <BoxSC>
                        {name}
                        <TableSortLabel
                          sortingColumn={field}
                          sortingField={sortingField}
                          orderDirection={sortingOrder}
                        />
                      </BoxSC>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {input && notificationsData.length === 0 && (
                  <Box sx={{ m: 2 }}>
                    <Typography color="textPrimary" variant="body1">
                      Nisu pronađeni rezultati.
                    </Typography>
                  </Box>
                )}
                {notificationsData.map((notification) => (
                  <TableRow hover key={notification.id}>
                    <TableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {notification.heading}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {clicked ? (
                          <Typography color="textPrimary" variant="body1">
                            {notificationId === notification.id ? (
                              <>
                                {notification.data}
                                <Button
                                  onClick={() => setClicked(false)}
                                  sx={{ ml: 1 }}
                                >
                                  Prikaži manje
                                </Button>
                              </>
                            ) : notification.data.length > 100 ? (
                              <>
                                {notification.data.slice(0, 100)}
                                <Button
                                  onClick={() => handleShow(notification)}
                                  sx={{ ml: 1 }}
                                >
                                  Prikaži više
                                </Button>
                              </>
                            ) : (
                              notification.data.slice(0, 100)
                            )}
                          </Typography>
                        ) : (
                          <Typography color="textPrimary" variant="body1">
                            {notification.data.length > 100 ? (
                              <>
                                {notification.data.slice(0, 100)}
                                <Button
                                  onClick={() => handleShow(notification)}
                                  sx={{ ml: 1 }}
                                >
                                  Prikaži više
                                </Button>
                              </>
                            ) : (
                              notification.data
                            )}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[
                20,
                50,
                100,
                { label: "Prikaži sve", value: totalItems },
              ]}
              labelRowsPerPage={<span>Redovi:</span>}
              labelDisplayedRows={({ page }) => {
                return `Stranica: ${page + 1}`;
              }}
              backIconButtonProps={{
                color: "primary",
              }}
              nextIconButtonProps={{ color: "primary" }}
              SelectProps={{
                inputProps: {
                  "aria-label": "Broj stranica",
                },
              }}
              showFirstButton={true}
              showLastButton={true}
            />
          </TableContainer>
        </Card>
      )}
    </>
  );
});

export default NotificationsResult;
