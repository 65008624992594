import { store } from "@risingstack/react-easy-state";
import axios from "axios";

import { API_ENDPOINT } from "../components/backoffice/config/constants";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  }
}

export const auth = store({
  user: null,
  isLoggedIn: false,
  isLoading: false,
  errors: null,
  forgotPasswordSuccess: false,

  async login(email, password) {
    auth.isLoading = true;
    try {
      const response = await axios.post(`${API_ENDPOINT}/system/login`, {
        email: email,
        password: password,
      });

      if (!response) {
        const responseJson = await response.json();
        auth.errors = responseJson;
        throw Error(response.statusText);
      }
      auth.user = response.data;
      localStorage.setItem("user", JSON.stringify(auth.user));
      auth.isLoggedIn = true;

      auth.isLoading = false;
    } catch (error) {
      auth.user = null;
      auth.errors = error;
      auth.isLoading = false;
    }
  },

  async logout() {
    auth.isLoading = true;
    try {
      localStorage.removeItem("user");
      auth.user = null;
      auth.isLoggedIn = false;
      auth.isLoading = false;
    } catch (error) {
      auth.isLoading = false;
    }
  },

  async forgotPassword(email) {
    auth.isLoading = true;
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/system/forgot-password`,
        {
          email: email,
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }

      auth.forgotPasswordSuccess = true;
      auth.errors = null;
      auth.isLoading = false;
    } catch (error) {
      auth.errors = error;
      auth.isLoading = false;
    }
  },

  getUser() {
    auth.isLoading = true;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      auth.user = user;
      auth.isLoggedIn = true;
      auth.isLegit = true;
    }
    auth.isLoading = false;
  },
});

export const cities = store({
  cities: null,
  city: null,
  success: false,
  isLoading: false,

  async getCities() {
    cities.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/cities`, {
        headers: { ...headers },
      });

      if (!response) {
        throw Error(response.statusText);
      }

      cities.cities = response.data.data;
      cities.isLoading = false;
    } catch (error) {
      console.log(error);
      cities.isLoading = false;
    }
  },

  async getCity(id) {
    cities.isLoading = true;

    const headers = authHeader();
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/cities/${id}`, {
        headers: { ...headers },
      });

      if (!response) {
        throw Error(response.statusText);
      }

      cities.city = response.data;
      cities.isLoading = false;
    } catch (error) {
      console.log(error);
      cities.isLoading = false;
    }
  },

  async addCity(name, postal_code) {
    cities.isLoading = true;

    const headers = authHeader();
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/system/cities`,
        { name: name, postal_code: postal_code },
        {
          headers: { ...headers },
        }
      );
      if (!response) {
        throw Error(response.statusText);
      }
      cities.isLoading = false;
    } catch (error) {
      console.log(error);
      cities.isLoading = false;
    }
  },

  async editCity(city, postal_code, id) {
    cities.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.patch(
        `${API_ENDPOINT}/system/cities/${id}`,
        { name: city, postal_code: postal_code },
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }
      cities.success = true;
      cities.isLoading = false;
    } catch (error) {
      console.log(error);
      cities.success = false;
      cities.isLoading = false;
    }
  },

  async delete(id) {
    cities.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}/system/cities/${id}`,
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }

      await cities.getCities();
      cities.isLoading = false;
    } catch (error) {
      console.log(error);
      cities.isLoading = false;
    }
  },
});

export const streets = store({
  streets: null,
  street: null,
  isLoading: null,
  success: false,

  async getStreets() {
    streets.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/system/streets?include=city`,
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }

      streets.streets = response.data.data;
      streets.isLoading = false;
    } catch (error) {
      console.log(error);
      streets.isLoading = false;
    }
  },

  async getStreet(id) {
    streets.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/system/streets/${id}?include=city`,
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }

      streets.street = response.data;
      streets.isLoading = false;
    } catch (error) {
      console.log(error);
      streets.isLoading = false;
    }
  },

  async addStreet(
    name,
    starting_number,
    ending_number,
    city_id,
    measurement_day
  ) {
    streets.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/system/streets`,
        {
          name,
          starting_number,
          ending_number,
          city_id,
          measurement_day,
        },
        {
          headers: { ...headers },
        }
      );
      if (!response) {
        throw Error(response.statusText);
      }
      streets.isLoading = false;
    } catch (error) {
      console.log(error);
      streets.isLoading = false;
    }
  },

  async editStreet(
    name,
    starting_number,
    ending_number,
    city_id,
    measurement_day,
    id
  ) {
    streets.isLoading = true;

    const headers = authHeader();
    try {
      const response = await axios.patch(
        `${API_ENDPOINT}/system/streets/${id}`,
        {
          name,
          starting_number,
          ending_number,
          city_id,
          measurement_day,
        },
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }
      streets.success = true;
      streets.isLoading = false;
    } catch (error) {
      console.log(error);
      streets.success = false;
      streets.isLoading = false;
    }
  },

  async delete(id) {
    streets.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}/system/streets/${id}`,
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }

      await streets.getStreets();
      streets.isLoading = false;
    } catch (error) {
      console.log(error);
      streets.isLoading = false;
    }
  },
});

export const users = store({
  users: null,
  user: null,
  isLoading: false,
  errors: null,
  success: false,

  async getUsers() {
    users.isLoading = true;

    const headers = authHeader();
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/users`, {
        headers: { ...headers },
      });

      if (!response) {
        throw Error(response.statusText);
      }
      users.users = response.data.data.splice(1);

      users.isLoading = false;
    } catch (error) {
      console.log(error);
      users.isLoading = false;
    }
  },

  async getUser(id) {
    users.isLoading = true;
    users.user = null;
    const headers = authHeader();
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/users/${id}`, {
        headers: { ...headers },
      });

      if (!response) {
        throw Error(response.statusText);
      }

      users.user = response.data;
      users.isLoading = false;
    } catch (error) {
      console.log(error);
      users.isLoading = false;
    }
  },

  async userDetails() {
    users.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/user-details`, {
        headers: { ...headers },
      });

      if (!response) {
        throw Error(response.statusText);
      }

      users.isLoading = false;
    } catch (error) {
      console.log(error);
      users.isLoading = false;
    }
  },

  async addUser(email, password, name, oib, user_code, company, role_id) {
    users.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/system/users`,
        {
          email,
          password,
          name,
          oib,
          user_code,
          company,
          role_id,
        },
        {
          headers: { ...headers },
        }
      );
      if (!response) {
        throw Error(response.statusText);
      }
      users.success = true;
      users.isLoading = false;
    } catch (error) {
      users.errors = error.response;
      users.isLoading = false;
    }
  },

  async editUser(email, password, name, oib, user_code, company, role_id, id) {
    users.isLoading = true;

    const headers = authHeader();
    try {
      var response = null;
      {
        !password
          ? (response = await axios.patch(
              `${API_ENDPOINT}/system/users/${id}`,
              {
                email,
                name,
                oib,
                user_code,
                company,
                role_id,
                id,
              },
              {
                headers: { ...headers },
              }
            ))
          : (response = await axios.patch(
              `${API_ENDPOINT}/system/users/${id}`,
              {
                email,
                password,
                name,
                oib,
                user_code,
                company,
                role_id,
                id,
              },
              {
                headers: { ...headers },
              }
            ));
      }

      if (!response) {
        throw Error(response.statusText);
      }

      users.success = true;
      users.isLoading = false;
      await users.getUsers();
    } catch (error) {
      console.log(error);
      users.isLoading = false;
    }
  },

  async delete(id) {
    users.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}/system/users/${id}`,
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }

      await users.getUsers();
      users.isLoading = false;
    } catch (error) {
      console.log(error);
      users.isLoading = false;
    }
  },
});

export const devices = store({
  devices: null,
  isLoading: null,

  async getDevices() {
    devices.isLoading = true;

    const headers = authHeader();
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/system/bills?include=device.owner,device.street`,
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            ...headers,
          },
        }
      );
      if (!response) {
        throw Error(response.statusText);
      }
      console.log(response.data);
      devices.devices = response.data.data;
      devices.isLoading = false;
    } catch (error) {
      console.log(error);
      devices.isLoading = false;
    }
  },
});

export const notifications = store({
  notifications: null,
  isLoading: false,
  success: false,

  async getNotifications() {
    notifications.isLoading = true;

    const headers = authHeader();
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/notifications`, {
        headers: { ...headers },
      });

      if (!response) {
        throw Error(response.statusText);
      }
      notifications.notifications = response.data.data;
      notifications.isLoading = false;
    } catch (error) {
      console.log(error);
      notifications.isLoading = false;
    }
  },

  async addNotification(heading, data, streets, broadcast) {
    notifications.isLoading = true;
    const headers = authHeader();
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/system/notifications`,
        {
          heading,
          data,
          streets,
          broadcast,
        },
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }

      notifications.success = true;
      notifications.isLoading = false;
    } catch (error) {
      notifications.isLoading = false;
    }
  },
});

export const bills = store({
  bills: null,
  bill: null,
  success: false,
  isLoading: false,
  isLoadingPDF: false,
  errors: null,
  async getBills() {
    bills.isLoading = true;

    const headers = authHeader();
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/system/bills?include=device.street,device.owner`,
        {
          headers: { ...headers },
        }
      );

      if (!response) {
        throw Error(response.statusText);
      }
      bills.bills = response.data.data;
      bills.isLoading = false;
    } catch (error) {
      console.log(error);
      bills.isLoading = false;
    }
  },
  async getBill(id) {
    bills.isLoading = true;

    const headers = authHeader();
    try {
      const response = await axios.get(`${API_ENDPOINT}/system/bills/${id}`, {
        headers: { ...headers },
      });

      if (!response) {
        throw Error(response.statusText);
      }

      bills.bill = response.data;
      console.log(bills.bill);
      bills.isLoading = false;
      bills.success = true;
    } catch (error) {
      console.log(error);
      bills.isLoading = false;
      bills.success = false;
    }
  },
  async downloadPDF(id) {
    bills.isLoadingPDF = true;

    try {
      const response = await fetch(`${API_ENDPOINT}/system/pdf-bill/${id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          ...authHeader(),
        },
      });
      console.log(response);

      if (!response.ok) {
        throw Error(response.statusText);
      }
      console.log(bills.bill);
      const res = await response.blob();
      const href = URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `racun${bills?.bill?.id ? bills.bill.id : ""}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      bills.isLoadingPDF = false;
    } catch (error) {
      bills.isLoadingPDF = false;
      console.log(error);
    }
  },
});
