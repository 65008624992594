import React, { useEffect, useState } from "react";
import Layout from "components/backoffice/hoc/layout";
import { users } from "../../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "components/backoffice/utils/loader";
import AdminModal from "../../utils/adminModal";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usersSchema } from "../../schemas/index";

const EditUser = view(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(usersSchema) });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    oib: null,
    user_code: null,
    company: "",
    role_id: 0,
    id: null,
  });

  const onSubmit = async (data) => {
    if (!data.oib) {
      data.oib = null;
    }
    if (!data.user_code) {
      data.user_code = null;
    }
    data.role_id === 2
      ? await users.editUser(
          data.email,
          data.password,
          data.name,
          data.oib,
          data.user_code,
          data.company,
          data.role_id,
          params.id
        )
      : handleOpen();
    setFormData({
      ...formData,
      email: data.email,
      password: data.password,
      name: data.name,
      oib: data.oib,
      user_code: data.user_code,
      company: data.company,
      role_id: data.role_id,
      id: params.id,
    });
    if (users.success) {
      navigate("/backoffice/korisnici");
    }
  };

  autoEffect(async () => {
    if (!users.user) {
      await users.getUser(params.id);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      users.success = false;
      users.errors = null;
      users.user = null;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {users.user ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              position: "sticky",
              top: 0,
              boxShadow: 3,
              borderRadius: 4,
            }}
          >
            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flex: 1,
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  style={{
                    overflow: "hidden",
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                  }}
                  variant="h4"
                >
                  Uredi "{users.user.name}"
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 4,
              mx: 2,
              mb: 2,
              height: "100%",
              overflow: "auto",
            }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                mb: 2,
              }}
            >
              <Box sx={{ maxWidth: 500, flexGrow: 1, mx: 2 }}>
                <form>
                  <TextField
                    fullWidth
                    label="Uloga korisnika"
                    margin="normal"
                    id="role_id"
                    name="role_id"
                    type="text"
                    variant="outlined"
                    {...register("role_id")}
                    defaultValue={users.user.role_id}
                    select
                  >
                    <MenuItem value="" disabled>
                      Uloga korisnika
                    </MenuItem>
                    <MenuItem value={1}>Admin</MenuItem>
                    <MenuItem value={2}>Korisnik </MenuItem>
                    <MenuItem value={3}>Inkasator</MenuItem>
                  </TextField>
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.role_id?.message}
                  </Typography>

                  <TextField
                    defaultValue={users.user.email.toString()}
                    fullWidth
                    label="Email"
                    margin="normal"
                    id="email"
                    name="email"
                    type="text"
                    variant="outlined"
                    {...register("email")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.email?.message}
                  </Typography>

                  <TextField
                    fullWidth
                    label="Nova lozinka"
                    margin="normal"
                    id="password"
                    name="password"
                    type="password"
                    variant="outlined"
                    {...register("password")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.password?.message}
                  </Typography>

                  <TextField
                    defaultValue={users.user.name.toString()}
                    fullWidth
                    label="Ime i prezime"
                    margin="normal"
                    id="name"
                    name="name"
                    type="text"
                    variant="outlined"
                    {...register("name")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.name?.message}
                  </Typography>

                  <TextField
                    defaultValue={
                      users.user.oib ? users.user.oib.toString() : null
                    }
                    fullWidth
                    label="OIB"
                    margin="normal"
                    id="oib"
                    name="oib"
                    type="number"
                    onKeyPress={(e) => {
                      !/[0-9]/.test(e.key) && e.preventDefault();
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 11);
                    }}
                    variant="outlined"
                    {...register("oib")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.oib?.message}
                  </Typography>

                  <TextField
                    defaultValue={
                      users.user.user_code
                        ? users.user.user_code.toString()
                        : null
                    }
                    fullWidth
                    label="Šifra korisnika"
                    margin="normal"
                    id="user_code"
                    name="user_code"
                    type="text"
                    variant="outlined"
                    {...register("user_code")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.user_code?.message}
                  </Typography>

                  <TextField
                    defaultValue={
                      users.user.company ? users.user.company.toString() : null
                    }
                    fullWidth
                    label="Tvrtka"
                    margin="normal"
                    id="company"
                    name="company"
                    type="text"
                    variant="outlined"
                    {...register("company")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.company?.message}
                  </Typography>

                  <Box
                    sx={{
                      py: 2,
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      style={{ backgroundColor: "#00BAFF" }}
                      fullWidth
                      disabled={users.isLoading ? true : false}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {users.isLoading ? "Učitavanje" : "Uredi"}
                    </Button>
                    <Button
                      style={{ backgroundColor: "gray" }}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        navigate("/backoffice/korisnici");
                      }}
                    >
                      Odustani
                    </Button>
                  </Box>
                </form>
                {open ? (
                  <AdminModal
                    operation="edit"
                    open={open}
                    handleClose={handleClose}
                    formData={formData}
                  />
                ) : null}
              </Box>
            </Card>
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </Layout>
  );
});

export default EditUser;
