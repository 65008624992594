import React from "react";
import { view } from "@risingstack/react-easy-state";
import { users } from "../../../../stores/BaseStore";
import Layout from "../../hoc/layout";
import UsersResult from "./usersResult";

const Users = view(() => {
  return (
    <Layout>
      <UsersResult users={users} />
    </Layout>
  );
});
export default Users;
