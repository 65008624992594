import React, { useState, useEffect, useMemo } from "react";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { useLocation } from "react-router-dom";
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableContainer,
} from "@mui/material";
import Loader from "components/backoffice/utils/loader";
import UsersToolbar from "./usersToolbar";
import DeleteModal from "components/backoffice/utils/deleteModal";
import { IconDeleteSC, IconEditSC, BoxSC } from "../../styled/styled";
import TableSortLabel from "../../utils/tableSortLabel";
import UserDetailModal from "components/backoffice/utils/userDetailModal";
import { sortArray } from "./sort";

const UsersResult = view(({ users }) => {
  const location = useLocation();

  const [allUsers, setAllUsers] = useState(null);
  const [success, setSuccess] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);

  const [input, setInput] = useState("");

  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openDetail, setOpenDetail] = useState(false);
  const handleOpenDetail = () => setOpenDetail(true);
  const handleCloseDetail = () => setOpenDetail(false);

  const [deleteID, setDeleteID] = useState("");

  const headers = [
    { name: "Ime i prezime", field: "name", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Šifra korisnika", field: "user_code", sortable: true },
    { name: "Tvrtka", field: "company", sortable: true },
    { name: "Uloga", field: "role_id", sortable: true },
  ];

  const usersData = useMemo(() => {
    let computedUsers = allUsers;
    if (!computedUsers) computedUsers = [];

    if (input) {
      computedUsers = computedUsers.filter(
        (user) =>
          user.name?.toLowerCase().includes(input.toLowerCase()) ||
          user.email?.toLowerCase().includes(input.toLowerCase()) ||
          (!user.user_code
            ? null
            : user.user_code?.toLowerCase().includes(input.toLowerCase())) ||
          (!user.company
            ? null
            : user.company?.toLowerCase().includes(input.toLowerCase()))
      );
    }
    if (computedUsers) setTotalItems(computedUsers.length);
    //Sorting comments
    if (sortingField) {
      sortArray(computedUsers, sortingField, sortingOrder);
    }
    //Current Page slice
    return computedUsers.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [allUsers, page, rowsPerPage, input, sortingField, sortingOrder]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSortingChange = (field) => {
    const order = sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
  };

  const handleID = (id) => {
    setDeleteID(id);
  };

  autoEffect(async () => {
    if (!users.users) {
      await users.getUsers();
      setAllUsers(users.users);
    }
  }, [location]);

  useEffect(async () => {
    if (success || deleteSuccess) {
      await users.getUsers();
      setAllUsers(users.users);
      setSuccess(false);
      setDeleteSuccess(false);
    }
  }, [success, deleteSuccess]);

  useEffect(() => {
    return () => {
      users.users = null;
    };
    // eslint-disable-next-line
  }, []);

  if (usersData.length === 0 && !input) {
    return (
      <Box>
        <UsersToolbar input={input} setInput={setInput} disabled={true} />
        {users.isLoading ? (
          <Loader />
        ) : (
          <Typography
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            Nema niti jednog korisnika
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <UsersToolbar input={input} setInput={setInput} disabled={false} />

      {users.users && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            overflow: "auto",
            mb: 2,
            mx: 2,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map(({ name, field, sortable }) => (
                    <TableCell
                      key={field}
                      onClick={() => (sortable ? onSortingChange(field) : null)}
                    >
                      <BoxSC>
                        {name}
                        <TableSortLabel
                          sortingColumn={field}
                          sortingField={sortingField}
                          orderDirection={sortingOrder}
                        />
                      </BoxSC>
                    </TableCell>
                  ))}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {input && usersData.length === 0 && (
                  <Box sx={{ m: 2 }}>
                    <Typography color="textPrimary" variant="body1">
                      Nisu pronađeni rezultati.
                    </Typography>
                  </Box>
                )}
                {usersData.map((user) => (
                  <TableRow hover key={user.id}>
                    <TableCell
                      padding="normal"
                      size="medium"
                      style={{ width: "20%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {user.name}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "20%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {user.email}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "20%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {user.user_code ? (
                          <Typography color="textPrimary" variant="body1">
                            {user.user_code}
                          </Typography>
                        ) : (
                          <Typography color="textPrimary" variant="body1">
                            Ne postoji
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "20%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {user.company ? (
                          <Typography color="textPrimary" variant="body1">
                            {user.company}
                          </Typography>
                        ) : (
                          <Typography color="textPrimary" variant="body1">
                            Nema tvrtke
                          </Typography>
                        )}
                      </Box>
                    </TableCell>

                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "20%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {user.role_id === 1 ? (
                          <Typography color="textPrimary" variant="body1">
                            Admin
                          </Typography>
                        ) : user.role_id === 2 ? (
                          <Typography color="textPrimary" variant="body1">
                            Korisnik
                          </Typography>
                        ) : user.role_id === 3 ? (
                          <Typography color="textPrimary" variant="body1">
                            Inkasator
                          </Typography>
                        ) : (
                          <Typography color="textPrimary" variant="body1">
                            Admin
                          </Typography>
                        )}
                      </Box>
                    </TableCell>

                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "10%" }}
                    >
                      <Button
                        onClick={() => {
                          handleOpenDelete();
                          handleID(user.id);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          color: "#004A66",
                          mr: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        Briši <IconDeleteSC />
                      </Button>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      size="small"
                      style={{
                        width: "auto",
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleOpenDetail();
                          handleID(user.id);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          color: "#004A66",
                          gap: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Detalji
                        <IconEditSC />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[
                20,
                50,
                100,
                { label: "Prikaži sve", value: totalItems },
              ]}
              labelRowsPerPage={<span>Redovi:</span>}
              labelDisplayedRows={({ page }) => {
                return `Stranica: ${page + 1}`;
              }}
              backIconButtonProps={{
                color: "primary",
              }}
              nextIconButtonProps={{ color: "primary" }}
              SelectProps={{
                inputProps: {
                  "aria-label": "Broj stranica",
                },
              }}
              showFirstButton={true}
              showLastButton={true}
            />
          </TableContainer>
          {openDelete ? (
            <DeleteModal
              operation="korisnika"
              open={openDelete}
              id={deleteID}
              handleClose={handleCloseDelete}
              setDeleteSuccess={setDeleteSuccess}
            />
          ) : null}
          {openDetail ? (
            <UserDetailModal
              open={openDetail}
              id={deleteID}
              handleClose={handleCloseDetail}
              setSuccess={setSuccess}
            />
          ) : null}
        </Card>
      )}
    </>
  );
});

export default UsersResult;
