export const sortArray = (arr, col, orderBy) => {
    switch (col) {
      case "name":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) => {
              return a.name.localeCompare(b.name, undefined, {
                numeric: false,
  
                sensitivity: "base",
              });
            });
          case "desc":
            return arr.sort((a, b) => {
              return b.name.localeCompare(a.name, undefined, {
                numeric: false,
  
                sensitivity: "base",
              });
            });
        }
      case "measurement_day":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) =>
              a.measurement_day > b.measurement_day
                ? 1
                : b.measurement_day > a.measurement_day
                ? -1
                : 0
            );
          case "desc":
            return arr.sort((a, b) =>
              a.measurement_day < b.measurement_day
                ? 1
                : b.measurement_day < a.measurement_day
                ? -1
                : 0
            );
        }
      case "postal_code":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) => {
              a = Number(a.City.postal_code);
              b = Number(b.City.postal_code);
              if (a.length === b.length) {
                return a > b ? 1 : -1;
              }
              return a.length > b.length ? 1 : -1;
            });
          case "desc":
            return arr.sort((a, b) => {
              a = Number(a.City.postal_code);
              b = Number(b.City.postal_code);
              if (a.length === b.length) {
                return a < b ? 1 : -1;
              }
              return a.length < b.length ? 1 : -1;
            });
        }
      default:
        return arr;
    }
  };
  