import React, { useEffect, useState } from "react";
import { auth } from "../../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useNavigate } from "react-router-dom";

import { Box, Button, Link, TextField, Typography, Card } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordSchema } from "../../schemas/index";
import {
  ButtonContainerSC,
  ForgotPasswordContainerSC,
  WrapperSC,
} from "./styled";

const ForgotPassword = view(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(forgotPasswordSchema) });

  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    return () => {
      auth.forgotPasswordSuccess = false;
      auth.errors = null;
    };
    // eslint-disable-next-line
  }, []);

  autoEffect(() => {
    if (auth.isLoggedIn) {
      navigate("/backoffice");
    }

    if (auth.forgotPasswordSuccess) {
      setTimeout(() => navigate("/prijava"), 3000);
    }
  });

  autoEffect(() => {
    if (auth.errors?.message === "Request failed with status code 404") {
      setEmailError("E-mail nije pronađen u bazi podataka");
    }
  });

  const onSubmit = async (data) => {
    await auth.forgotPassword(data.email);
  };

  return (
    <>
      <div className="auth_container">
        {!auth.forgotPasswordSuccess ? (
          <WrapperSC>
            <Card
              style={{
                width: "100%",
                maxWidth: "400px",
                backgroundColor: "#FAFAFA",
              }}
            >
              <Box sx={{ m: 2 }}>
                <Box sx={{ mb: 1 }}>
                  <Typography
                    color="textPrimary"
                    sx={{
                      fontSize: "30px",
                      textAlign: "center",
                      color: "#333444",
                    }}
                  >
                    Zaboravljena lozinka
                  </Typography>
                </Box>
                <form>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    id="email"
                    name="email"
                    type="text"
                    variant="outlined"
                    {...register("email")}
                    onInput={() => {
                      setEmailError("");
                    }}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {emailError || errors.email?.message}
                  </Typography>

                  <ButtonContainerSC>
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      style={{
                        backgroundColor: "#00BAFF",
                        maxWidth: "260px",
                      }}
                      fullWidth
                      disabled={auth.isLoading ? true : false}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {auth.isLoading ? "Učitavanje" : "Pošalji"}
                    </Button>
                  </ButtonContainerSC>
                </form>

                <ForgotPasswordContainerSC>
                  <Typography color="textSecondary" variant="body2">
                    <Link
                      href="/prijava"
                      variant="subtitle2"
                      underline="hover"
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      Povratak na prijavu.
                    </Link>
                  </Typography>
                </ForgotPasswordContainerSC>
              </Box>
            </Card>
          </WrapperSC>
        ) : (
          <Box
            component="main"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
            }}
          >
            <Box sx={{ mt: 8 }} maxWidth="xs">
              <Card style={{ backgroundColor: "#FAFAFA" }}>
                <Box sx={{ m: 2 }} maxWidth="xs">
                  <Typography
                    color="textPrimary"
                    variant="h4"
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    Link za izmjenu lozinke je poslan na Vaš mail.
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Box>
        )}
      </div>
    </>
  );
});

export default ForgotPassword;
