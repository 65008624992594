import React, { useState, useEffect, useMemo } from "react";
import DeliveryToolbar from "./deliveryToolbar";
import { useLocation } from "react-router-dom";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loader from "components/backoffice/utils/loader";
import { BoxSC } from "../../styled/styled";
import TableSortLabel from "../../utils/tableSortLabel";
import { sortArray } from "./sort";
import { format } from "date-fns";

const headers = [
  { name: "Datum", field: "reading_date", sortable: true },
  { name: "Korisnik", field: "name", sortable: true },
  { name: "Stanje brojila", field: "measurement_amount", sortable: false },
  { name: "Naziv ulice", field: "street_name", sortable: true },
  { name: "Kućni broj", field: "street_number", sortable: true },
];

const DeliveryResult = view(({ devices }) => {
  const location = useLocation();
  const [allDevices, setAllDevices] = useState(null);

  console.log(devices);

  const [input, setInput] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const devicesData = useMemo(() => {
    let computedDevices = allDevices;
    if (!computedDevices) computedDevices = [];
    if (startDate || endDate) {
      computedDevices = computedDevices.filter((device) => {
        if (startDate) {
          var from = new Date(startDate);
          from.setDate(from.getDate() - 1);
        }
        if (endDate) {
          var to = new Date(endDate);
          to.setDate(to.getDate() - 1);
        }
        const today = new Date(device.reading_date);
        if (today >= from && today <= to) {
          return true;
        } else if (!to && today >= from) {
          return true;
        } else if (!from && today <= to) {
          return true;
        } else {
          return null;
        }
      });
    }

    if (input) {
      computedDevices = computedDevices.filter(
        (device) =>
          device.Device?.Owner.name
            .toLowerCase()
            .includes(input.toLowerCase()) ||
          device.Device?.Street.name
            .toLowerCase()
            .includes(input.toLowerCase()) ||
          device.Device?.street_number
            .toLowerCase()
            .includes(input.toLowerCase())
      );
    }

    //Sorting deliveries
    if (sortingField) {
      sortArray(computedDevices, sortingField, sortingOrder);
    } else {
      sortArray(computedDevices, "reading_date", "desc");
    }
    //Current Page slice
    return computedDevices;
  }, [allDevices, input, startDate, endDate, sortingField, sortingOrder]);

  const onSortingChange = (field) => {
    const order = sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
  };

  const changeFormat = (updated_at) => {
    const mys = updated_at.slice(0, 10);
    const parts = mys.split("-");

    if (parts[2][0].toString() === "0") {
      parts[2] = parts[2][1];
    }
    if (parts[1][0].toString() === "0") {
      parts[1] = parts[1][1];
    }
    return parts[2] + "." + parts[1] + "." + parts[0] + ".";
  };

  autoEffect(async () => {
    if (!devices.devices) {
      await devices.getDevices();
      setAllDevices(devices.devices);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      devices.devices = null;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && startDate > endDate) {
      alert("Završni datum ne smije biti manji od početnog datuma.");
      setEndDate("");
    }
  }, [startDate, endDate]);

  if (devicesData.length === 0 && !input && !startDate && !endDate) {
    return (
      <Box>
        <DeliveryToolbar
          input={input}
          setInput={setInput}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          disabled={true}
        />
        {devices.isLoading ? (
          <Loader />
        ) : (
          <Typography
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            Nema niti jednog očitanja
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <DeliveryToolbar
        input={input}
        setInput={setInput}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        disabled={false}
      />
      {devicesData && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            overflow: "auto",
            mb: 2,
            mx: 2,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map(({ name, field, sortable }) => (
                  <TableCell
                    key={field}
                    onClick={() => (sortable ? onSortingChange(field) : null)}
                  >
                    <BoxSC>
                      {name}
                      {field !== "measurement_amount" ? (
                        <TableSortLabel
                          sortingColumn={field}
                          sortingField={sortingField}
                          orderDirection={sortingOrder}
                        />
                      ) : null}
                    </BoxSC>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(input || startDate || endDate) && devicesData.length === 0 && (
                <Box sx={{ m: 2 }}>
                  <Typography color="textPrimary" variant="body1">
                    Nisu pronađeni rezultati.
                  </Typography>
                </Box>
              )}
              {devicesData.map((device) => (
                <TableRow hover key={device.id}>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {format(new Date(device.reading_date), "d.M.yyyy.")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {device.Device?.Owner.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {device.water_gauge}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {!device.Device?.Street ? (
                          <Typography component={"span"}>-</Typography>
                        ) : (
                          device.Device?.Street.name
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {!device.Device?.street_number ? (
                          <Typography component={"span"}>-</Typography>
                        ) : (
                          device.Device?.street_number
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      )}
    </>
  );
});

export default DeliveryResult;
