import React, { useState, useEffect } from "react";
import Layout from "components/backoffice/hoc/layout";
import { users } from "../../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usersSchema } from "../../schemas/index";
import AdminModal from "components/backoffice/utils/adminModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const AddUser = view(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(usersSchema) });

  const navigate = useNavigate();
  const [errorEmail, setErrorEmail] = useState("");
  const [errorOib, setErrorOib] = useState("");
  const [errorUserCode, setErrorUserCode] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    oib: null,
    user_code: null,
    company: "",
    role_id: 0,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmit = async (data) => {
    if (!data.oib) {
      data.oib = null;
    }
    if (!data.user_code) {
      data.user_code = null;
    }
    data.role_id === 2
      ? await users.addUser(
          data.email,
          data.password,
          data.name,
          data.oib,
          data.user_code,
          data.company,
          data.role_id
        )
      : handleOpen();
    setFormData({
      ...formData,
      email: data.email,
      password: data.password,
      name: data.name,
      oib: data.oib,
      user_code: data.user_code,
      company: data.company,
      role_id: data.role_id,
    });

    if (users.success) {
      navigate("/backoffice/korisnici");
    }
  };

  autoEffect(() => {
    if (
      users.errors &&
      users.errors.data.errors.email &&
      users.errors.data.errors.email[0] === "The email has already been taken."
    ) {
      setErrorEmail("Korisnik s ovim e-mailom već postoji");
    }
    if (
      users.errors &&
      users.errors.data.errors.oib &&
      users.errors.data.errors.oib[0] === "The oib has already been taken."
    ) {
      setErrorOib("Korisnik s ovim oib-om već postoji");
    }
    if (
      users.errors &&
      users.errors.data.errors.user_code &&
      users.errors.data.errors.user_code[0] ===
        "The user code has already been taken."
    ) {
      setErrorUserCode("Korisnik s ovom šifrom već postoji");
    }
  });

  useEffect(() => {
    return () => {
      users.success = false;
      users.errors = null;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          position: "sticky",
          top: 0,
          zIndex: 1,
          boxShadow: 3,
          borderRadius: 4,
        }}
      >
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h4">Dodaj korisnika</Typography>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 4,
          mx: 2,
          mb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            mb: 2,
          }}
        >
          <Box sx={{ maxWidth: 500, flexGrow: 1, mx: 2 }}>
            <form>
              <TextField
                fullWidth
                label="Uloga korisnika"
                margin="normal"
                id="role_id"
                name="role_id"
                type="text"
                variant="outlined"
                {...register("role_id")}
                defaultValue={2}
                select
              >
                <MenuItem value="" disabled>
                  Uloga korisnika
                </MenuItem>
                <MenuItem value={1}>Admin</MenuItem>
                <MenuItem value={2}>Korisnik </MenuItem>
                <MenuItem value={3}>Inkasator</MenuItem>
              </TextField>
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errors.role_id?.message}
              </Typography>

              <TextField
                fullWidth
                label="Email"
                margin="normal"
                id="email"
                name="email"
                type="text"
                variant="outlined"
                {...register("email")}
                onInput={() => {
                  setErrorEmail("");
                }}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errorEmail || errors.email?.message}
              </Typography>

              <TextField
                id="password"
                name="password"
                margin="normal"
                fullWidth
                label="Lozinka"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleShowPassword}
                      >
                        {!showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                {...register("password")}
              />

              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errors.password?.message}
              </Typography>

              <TextField
                fullWidth
                label="Ime i prezime"
                margin="normal"
                id="name"
                name="name"
                type="text"
                variant="outlined"
                {...register("name")}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errors.name?.message}
              </Typography>

              <TextField
                fullWidth
                label="OIB"
                margin="normal"
                id="oib"
                name="oib"
                type="number"
                onKeyPress={(e) => {
                  !/[0-9]/.test(e.key) && e.preventDefault();
                }}
                onInput={(e) => {
                  setErrorOib("");
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 11);
                }}
                variant="outlined"
                {...register("oib")}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errorOib || errors.oib?.message}
              </Typography>

              <TextField
                fullWidth
                label="Šifra korisnika"
                margin="normal"
                id="user_code"
                name="user_code"
                type="text"
                variant="outlined"
                onInput={(e) => {
                  setErrorUserCode("");
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 11);
                }}
                {...register("user_code")}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errorUserCode || errors.user_code?.message}
              </Typography>

              <TextField
                fullWidth
                label="Tvrtka"
                margin="normal"
                id="company"
                name="company"
                type="text"
                variant="outlined"
                {...register("company")}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errors.company?.message}
              </Typography>

              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                }}
              >
                <Button
                  onClick={handleSubmit(onSubmit)}
                  style={{ backgroundColor: "#00BAFF" }}
                  fullWidth
                  disabled={users.isLoading ? true : false}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {users.isLoading ? "Učitavanje" : "Dodaj"}
                </Button>
                <Button
                  style={{ backgroundColor: "gray" }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    navigate("/backoffice/korisnici");
                  }}
                >
                  Odustani
                </Button>
              </Box>
            </form>
            {open ? (
              <AdminModal
                operation="add"
                open={open}
                handleClose={handleClose}
                formData={formData}
              />
            ) : null}
          </Box>
        </Card>
      </Box>
    </Layout>
  );
});

export default AddUser;
