export const sortArray = (arr, col, orderBy) => {
    switch (col) {
      case "heading":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) => {
              return a.heading.localeCompare(b.heading, undefined, {
                numeric: false,
  
                sensitivity: "base",
              });
            });
          case "desc":
            return arr.sort((a, b) => {
              return b.heading.localeCompare(a.heading, undefined, {
                numeric: false,
  
                sensitivity: "base",
              });
            });
        }
      case "data":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) => {
              return a.data.localeCompare(b.data, undefined, {
                numeric: false,
  
                sensitivity: "base",
              });
            });
          case "desc":
            return arr.sort((a, b) => {
              return b.data.localeCompare(a.data, undefined, {
                numeric: false,
  
                sensitivity: "base",
              });
            });
        }
      default:
        return arr;
    }
  };
  