import React from "react";
import Layout from "components/backoffice/hoc/layout";
import { cities } from "../../../../stores/BaseStore";
import { view } from "@risingstack/react-easy-state";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { placesSchema } from "../../schemas/index";

const AddPlace = view(() => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(placesSchema) });

  const onSubmit = async (data) => {
    await cities.addCity(data.name, data.postal_code);
    navigate("/backoffice/mjesta");
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          position: "sticky",
          top: 0,
          zIndex: 1,
          boxShadow: 3,
          borderRadius: 4,
        }}
      >
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h4">Dodaj mjesto</Typography>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 4,
          mx: 2,
          mb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            mb: 2,
          }}
        >
          <Box sx={{ maxWidth: 500, flexGrow: 1, mx: 2 }}>
            <form>
              <TextField
                fullWidth
                label="Naziv"
                margin="normal"
                id="name"
                name="name"
                type="text"
                variant="outlined"
                {...register("name")}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errors.name?.message}
              </Typography>

              <TextField
                fullWidth
                label="Poštanski broj"
                margin="normal"
                id="postal_code"
                name="postal_code"
                type="number"
                variant="outlined"
                onKeyPress={(e) => {
                  !/[0-9]/.test(e.key) && e.preventDefault();
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 5);
                }}
                {...register("postal_code")}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errors.postal_code?.message}
              </Typography>
              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                }}
              >
                <Button
                  onClick={handleSubmit(onSubmit)}
                  style={{ backgroundColor: "#00BAFF" }}
                  fullWidth
                  disabled={cities.isLoading ? true : false}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {cities.isLoading ? "Učitavanje" : "Dodaj"}
                </Button>
                <Button
                  style={{ backgroundColor: "gray" }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    navigate("/backoffice/mjesta");
                  }}
                >
                  Odustani
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </Layout>
  );
});

export default AddPlace;
