import { view, autoEffect } from "@risingstack/react-easy-state";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { auth } from "../../../stores/BaseStore";

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`;

const ProtectedRoute = view(() => {
  const navigate = useNavigate();

  autoEffect(() => {
    auth.getUser();

    if (!auth.isLoggedIn) {
      navigate("/prijava");
    }
  });

  return (
    <>
      {auth.isLoggedIn ? (
        <Wrapper>
          <main style={{ width: "100%", overflowY: "hidden" }}>
            <Outlet />
          </main>
        </Wrapper>
      ) : null}
    </>
  );
});

export default ProtectedRoute;
