import React from "react";
import Sidebar from "../components/sidebar";
import { Box } from "@mui/material";
import "../backoffice.css";

const Layout = (props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            height: "100%",
            overflowY: "scroll",
            minWidth: 250,
            backgroundColor: "#002533",
            color: "#FFFFFF",
          }}
        >
          <Sidebar />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flex: 1,
            height: "auto",
            overflow: "hidden",
            backgroundColor: "#f3f5f9",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            {props.children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
