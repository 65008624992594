import { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ProtectedRoute from "./backoffice/hoc/authGuard";

import Auth from "./backoffice/auth/";
import ForgotPassword from "./backoffice/auth/forgot_password/index";

import Users from "./backoffice/dashboard/users/users";
import AddUser from "./backoffice/dashboard/users/addUser";
import EditUser from "./backoffice/dashboard/users/editUser";

import Places from "./backoffice/dashboard/places/places";
import AddPlace from "./backoffice/dashboard/places/addPlace";
import EditPlace from "./backoffice/dashboard/places/editPlace";

import Streets from "./backoffice/dashboard/streets/streets";
import AddStreet from "./backoffice/dashboard/streets/addStreet.js";
import EditStreet from "./backoffice/dashboard/streets/editStreet";

import Delivery from "./backoffice/dashboard/readings_delivery/readings_delivery";

import Bills from "./backoffice/dashboard/bills/bills";

import Notifications from "./backoffice/dashboard/notifications/notifications";
import AddNotification from "./backoffice/dashboard/notifications/addNotification";
const Wrapper = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/zaboravljenalozinka" element={<ForgotPassword />} />
        <Route path="/prijava" element={<Auth />} />
        <Route path="/" element={<Navigate to="/prijava" />} />

        <Route path="/backoffice" element={<ProtectedRoute />}>
          <Route path="/backoffice/korisnici" element={<Users />} />
          <Route path="/backoffice/korisnici/dodaj" element={<AddUser />} />
          <Route
            path="/backoffice/korisnici/uredi/:id"
            element={<EditUser />}
          />

          <Route path="/backoffice/mjesta" element={<Places />} />
          <Route path="/backoffice/mjesta/dodaj" element={<AddPlace />} />
          <Route path="/backoffice/mjesta/uredi/:id" element={<EditPlace />} />

          <Route path="/backoffice/ulice" element={<Streets />} />
          <Route path="/backoffice/ulice/dodaj" element={<AddStreet />} />
          <Route path="/backoffice/ulice/uredi/:id" element={<EditStreet />} />

          <Route path="/backoffice/obavijesti" element={<Notifications />} />

          <Route
            path="/backoffice/obavijesti/dodaj"
            element={<AddNotification />}
          />

          <Route path="/backoffice/dostava-ocitanja" element={<Delivery />} />

          <Route path="/backoffice/racuni" element={<Bills />} />
        </Route>
      </Routes>
    </Fragment>
  );
};

export default Wrapper;
