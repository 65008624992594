import React from "react";
import { view } from "@risingstack/react-easy-state";
import { streets } from "../../../../stores/BaseStore";
import Layout from "../../hoc/layout";
import StreetsResult from "./streetsResult";

const Streets = view(() => {
  return (
    <Layout>
      <StreetsResult streets={streets} />
    </Layout>
  );
});
export default Streets;
