export const sortArray = (arr, col, orderBy) => {
    switch (col) {
      case "name":
        switch (orderBy) {
          case "desc":
          default:
            return arr.sort((a, b) => {
              return a.name?.localeCompare(b.name, undefined, {
                numeric: false,
                sensitivity: "base",
              });
            });
          case "asc":
            return arr.sort((a, b) => {
              return b.name?.localeCompare(a.name, undefined, {
                numeric: false,
                sensitivity: "base",
              });
            });
        }
      case "email":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) => {
              return a.email.localeCompare(b.email, undefined, {
                numeric: false,
                sensitivity: "base",
              });
            });
          case "desc":
            return arr.sort((a, b) => {
              return b.email.localeCompare(a.email, undefined, {
                numeric: false,
                sensitivity: "base",
              });
            });
        }
      case "user_code":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) => {
              if (!a.user_code) {
                a.user_code = "";
              }
              if (!b.user_code) {
                b.user_code = "";
              }
              return a.user_code.localeCompare(b.user_code, undefined, {
                numeric: false,
                sensitivity: "base",
              });
            });
          case "desc":
            return arr.sort((a, b) => {
              if (!a.user_code) {
                a.user_code = "";
              }
              if (!b.user_code) {
                b.user_code = "";
              }
              return b.user_code.localeCompare(a.user_code, undefined, {
                numeric: false,
                sensitivity: "base",
              });
            });
        }
      case "company":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) => {
              if (!a.company) {
                a.company = "";
              }
              if (!b.company) {
                b.company = "";
              }
              return a.company.localeCompare(b.company, undefined, {
                numeric: false,
                sensitivity: "base",
              });
            });
          case "desc":
            return arr.sort((a, b) => {
              if (!a.company) {
                a.user_code = "";
              }
              if (!b.company) {
                b.company = "";
              }
              return b.company.localeCompare(a.company, undefined, {
                numeric: false,
                sensitivity: "base",
              });
            });
        }
      case "role_id":
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) =>
              a.role_id > b.role_id ? 1 : b.role_id > a.role_id ? -1 : 0
            );
          case "desc":
            return arr.sort((a, b) =>
              a.role_id < b.role_id ? 1 : b.role_id < a.role_id ? -1 : 0
            );
        }
      default:
        return arr;
    }
  };
  