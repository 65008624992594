import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email format nije valjan")
    .required("Email je obvezan"),
  password: yup.string().required("Lozinka je obavezna"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email format nije valjan")
    .required("Email je obvezan"),
});

export const placesSchema = yup.object().shape({
  name: yup.string().required("Unesite naziv mjesta"),
  postal_code: yup
    .number()
    .required("Unesite poštanski broj")
    .typeError("Unesite poštanski broj")
    .test("len", "Poštanski broj mora imati 5 znamenki", (val) => {
      if (val) return val.toString().length === 5;
    }),
});

export const streetsSchema = yup.object().shape({
  name: yup.string().required("Unesite ulicu"),
  starting_number: yup.string().required("Unesite početni kućni broj"),
  ending_number: yup.string().required("Unesite završni kućni broj"),
  city_id: yup
    .number()
    .typeError("Unesite pripadajući grad")
    .required("Unesite pripadajući grad"),
  measurement_day: yup
    .number()
    .required("Unesite dan mjerenja")
    .typeError("Unesite dan mjerenja")
    .max(
      31,
      "Dan mjerenja ne smije biti veći od 31. Odnosi se na dan izlaska inkasatora na teren, a 3 dana prije Dana mjerenja korisnik će dobiti notifikacije za dostavu samoočitanja."
    )
    .min(
      1,
      "Dan mjerenja ne smije biti manji od 1. Odnosi se na dan izlaska inkasatora na teren, a 3 dana prije Dana mjerenja korisnik će dobiti notifikacije za dostavu samoočitanja."
    ),
});

export const usersSchema = yup.object().shape({
  role_id: yup.number().typeError("Unesite ulogu korisnika"),
  email: yup
    .string()
    .email("Email format nije valjan")
    .required("Email je obvezan"),
  password: yup
    .string()
    .required("Unesite lozinku")
    .min(6, "Lozinka mora imati bar 6 karaktera"),
  name: yup.string().required("Ime i prezime je obvezno"),
  oib: yup.string().when("role_id", (role_id, oib) => {
    if (role_id === 2) {
      return oib
        .required("OIB je obvezan")
        .typeError("OIB je obvezan")
        .test("len", "OIB mora imati 11 znamenki", (val) => val.length === 11);
    }

    return oib;
  }),
  user_code: yup.string().when("role_id", (role_id, user_code) => {
    if (role_id === 2) {
      return user_code.required("Šifra korisnika je obavezna");
    }

    return user_code;
  }),
  company: yup.string(),
});

export const usersEditSchema = yup.object().shape({
  role_id: yup.number().typeError("Unesite ulogu korisnika"),
  email: yup
    .string()
    .email("Email format nije valjan")
    .required("Email je obvezan"),
  password: yup.string(),
  name: yup.string(),
  oib: yup.string().when("role_id", (role_id, oib) => {
    if (role_id === 2) {
      return oib
        .required("OIB je obvezan")
        .typeError("OIB je obvezan")
        .test("len", "OIB mora imati 11 znamenki", (val) => val.length === 11);
    }

    return oib;
  }),
  user_code: yup.string().when("role_id", (role_id, user_code) => {
    if (role_id === 2) {
      return user_code.required("Šifra korisnika je obavezna");
    }

    return user_code;
  }),
  company: yup.string(),
});

export const notificationsSchema = yup.object().shape({
  heading: yup.string().required("Unesite naslov"),
  data: yup.string().required("Unesite poruku"),
  city_id: yup
    .array()
    .when("checked", {
      is: false,
      then: yup
        .array()
        .nullable()
        .required("Unesite bar jedan grad")
        .min(1, "Unesite bar jedan grad"),
    })

    .nullable(),
  street_id: yup
    .array()
    .when("checked", {
      is: false,
      then: yup
        .array()
        .nullable()
        .required("Unesite bar jednu ulicu")
        .min(1, "Unesite bar jednu ulicu"),
    })
    .nullable(),
});
