import React from "react";
import { view } from "@risingstack/react-easy-state";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useStyles } from "../../styled/styled";
import { useNavigate } from "react-router-dom";

const UsersToolbar = view(({ input, setInput, disabled }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          position: "sticky",
          top: 0,
          zIndex: 1,
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "36px 16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Box sx={{ flex: "40%" }}>
              <Typography style={{ fontSize: "2rem" }}>KORISNICI</Typography>
            </Box>
            <Box sx={{ flex: 1, flexGrow: 1 }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#00BAFF",
                  flex: 1,
                }}
                variant="contained"
                onClick={() => navigate("/backoffice/korisnici/dodaj")}
              >
                Dodaj
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "0px 16px",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ maxWidth: 500, flex: 1, mr: 1 }}>
            <TextField
              fullWidth
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  padding: 8,
                },
              }}
              style={{ padding: 0 }}
              placeholder="Pretraži korisnike"
              disabled={disabled}
              variant="outlined"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default UsersToolbar;
