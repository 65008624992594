import React, { useEffect, useState } from "react";
import Layout from "components/backoffice/hoc/layout";
import { streets, cities, notifications } from "../../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import NotificationModal from "../../utils/notificationModal";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { notificationsSchema } from "../../schemas/index";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddNotification = view(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(notificationsSchema) });

  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [checked, setChecked] = useState(false);

  const [formData, setFormData] = useState({
    heading: "",
    data: "",
    streets: [],
    broadcast: false,
  });

  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCityIds, setSelectedCityIds] = useState([]);
  const [selectedStreets, setSelectedStreets] = useState([]);
  const [selectedStreetIds, setSelectedStreetIds] = useState([]);

  const handleBoxChange = (event) => {
    setSelectedCities([]);
    setSelectedStreets([]);
    setChecked(event.target.checked);
  };

  const handleCitiesChange = (event) => {
    setSelectedStreets([]);
    setSelectedCities(event.target.value);
  };

  const handleSelectAllCities = (cities) => {
    if (selectedCities.length === 0) {
      cities.map((city) => setSelectedCities((prev) => [...prev, city]));
    } else {
      setSelectedCities([]);
      setSelectedStreets([]);
    }
  };

  const handleStreetsChange = (event) => {
    setSelectedStreets(event.target.value);
  };

  const handleSelectAllStreets = (cities) => {
    if (selectedStreets.length === 0) {
      cities.map((city) => setSelectedStreets((prev) => [...prev, city]));
    } else {
      setSelectedStreets([]);
    }
  };

  const onSubmit = async (data) => {
    setFormData({
      ...formData,
      heading: data.heading,
      data: data.data,
      streets: selectedStreetIds,
      broadcast: checked,
    });
    handleOpen();
  };

  autoEffect(async () => {
    if (!streets.streets) {
      await streets.getStreets();
    }
  }, [location]);

  autoEffect(async () => {
    if (!cities.cities) {
      await cities.getCities();
    }
  }, [location]);

  useEffect(() => {
    setSelectedCityIds([]);
    selectedCities.map((item) =>
      setSelectedCityIds((prevArray) => [...prevArray, item.id])
    );
  }, [selectedCities]);

  useEffect(() => {
    setSelectedStreetIds([]);
    selectedStreets.map((item) =>
      setSelectedStreetIds((prevArray) => [...prevArray, item.id])
    );
  }, [selectedStreets]);

  useEffect(() => {
    return () => {
      notifications.success = false;
      streets.streets = null;
      cities.cities = null;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          position: "sticky",
          top: 0,
          zIndex: 1,
          boxShadow: 3,
          borderRadius: 4,
        }}
      >
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h4">Dodaj obavijest</Typography>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          borderRadius: 4,
          boxShadow: 3,
          mx: 2,
          mb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            mb: 2,
          }}
        >
          <Box
            sx={{
              maxWidth: 600,
              flexGrow: 1,
              mx: 2,
            }}
          >
            <form>
              <Box
                sx={{
                  marginTop: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography>Obavijest svim korisnicima</Typography>
                <Checkbox
                  {...register("checked")}
                  color="primary"
                  checked={checked}
                  onChange={handleBoxChange}
                />
              </Box>

              {cities.cities ? (
                <>
                  <FormControl
                    disabled={checked ? true : false}
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    <InputLabel id="test-select-label">Grad</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="test-select-label"
                      label="Label"
                      id="city_id"
                      {...register("city_id")}
                      multiple
                      fullWidth
                      value={selectedCities}
                      onChange={handleCitiesChange}
                      renderValue={(selectedCities) =>
                        selectedCities.map((item) => item.name).join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        sx={{
                          padding: 0,
                        }}
                        key={"Select all"}
                      >
                        <ListItemText
                          style={{
                            flex: 1,
                            paddingLeft: 16,
                            paddingTop: 5,
                            paddingBottom: 5,
                          }}
                          primary="Odaberi sve"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleSelectAllCities(cities.cities);
                          }}
                        />
                      </MenuItem>
                      {cities.cities.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          <Checkbox
                            checked={
                              selectedCities.find(
                                (item) => item.id === option.id
                              )
                                ? true
                                : false
                            }
                          />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {selectedCities.length === 0 && !checked
                      ? errors.city_id?.message
                      : null}
                  </Typography>
                </>
              ) : (
                <TextField select label="Grad" margin="normal" fullWidth>
                  <MenuItem value="" disabled>
                    Morate dodati grad
                  </MenuItem>
                </TextField>
              )}

              {streets.streets ? (
                <>
                  <FormControl
                    disabled={checked ? true : false}
                    variant="outlined"
                    style={{ width: "100%", marginBottom: 10, marginTop: 16 }}
                  >
                    <InputLabel id="test-select-label">Ulica</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="test-select-label"
                      label="Label"
                      multiple
                      fullWidth
                      id="street_id"
                      {...register("street_id")}
                      value={selectedStreets}
                      onChange={handleStreetsChange}
                      renderValue={(selectedStreets) =>
                        selectedStreets.map((item) => item.name).join(", ")
                      }
                      MenuProps={MenuProps}
                      style={{ width: "100%" }}
                    >
                      {selectedCityIds.length === 0 ? (
                        <MenuItem disabled>Unesite grad</MenuItem>
                      ) : (
                        <MenuItem
                          sx={{
                            padding: 0,
                          }}
                          key={"Select all"}
                        >
                          <ListItemText
                            style={{
                              flex: 1,
                              paddingLeft: 16,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                            primary="Odaberi sve"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleSelectAllStreets(streets.streets);
                            }}
                          />
                        </MenuItem>
                      )}

                      {streets.streets
                        .filter((street) => {
                          if (!street.City) {
                            return null;
                          } else if (
                            selectedCityIds.indexOf(street.City.id) > -1
                          ) {
                            return street;
                          } else {
                            return null;
                          }
                        })
                        .map((option) => (
                          <MenuItem key={option.id} value={option}>
                            <Checkbox
                              checked={
                                selectedStreets.find(
                                  (item) => item.id === option.id
                                )
                                  ? true
                                  : false
                              }
                            />
                            <ListItemText
                              primary={`${option.name}, ${option.City.name}`}
                            />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {selectedStreets.length === 0 && !checked
                      ? errors.street_id?.message
                      : null}
                  </Typography>
                </>
              ) : (
                <TextField select label="Grad" margin="normal" fullWidth>
                  <MenuItem value="" disabled>
                    Morate dodati ulicu
                  </MenuItem>
                </TextField>
              )}

              <TextField
                fullWidth
                label="Naslov"
                margin="normal"
                id="heading"
                name="heading"
                type="text"
                variant="outlined"
                {...register("heading")}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errors.heading?.message}
              </Typography>
              <TextField
                fullWidth
                label="Poruka"
                margin="normal"
                id="data"
                name="data"
                type="text"
                minRows={6}
                variant="outlined"
                multiline
                {...register("data")}
              />
              <Typography
                style={{
                  marginTop: "0",
                  marginBottom: "0",
                  marginRight: "14px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
                color="error"
              >
                {errors.data?.message}
              </Typography>
              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                }}
              >
                <Button
                  onClick={handleSubmit(onSubmit)}
                  style={{ backgroundColor: "#00BAFF" }}
                  fullWidth
                  disabled={notifications.isLoading ? true : false}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {notifications.isLoading ? "Učitavanje" : "Dodaj"}
                </Button>
                <Button
                  style={{ backgroundColor: "gray" }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    navigate("/backoffice/obavijesti");
                  }}
                >
                  Odustani
                </Button>
              </Box>
            </form>
            {open ? (
              <NotificationModal
                open={open}
                handleClose={handleClose}
                formData={formData}
              />
            ) : null}
          </Box>
        </Card>
      </Box>
    </Layout>
  );
});

export default AddNotification;
