import React, { useEffect } from "react";
import Layout from "components/backoffice/hoc/layout";
import { streets, cities } from "../../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { streetsSchema } from "../../schemas/index";

const EditStreet = view(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(streetsSchema) });

  const onSubmit = async (data) => {
    await streets.editStreet(
      data.name,
      data.starting_number,
      data.ending_number,
      data.city_id,
      data.measurement_day,
      params.id
    );
    navigate("/backoffice/ulice");
  };

  autoEffect(async () => {
    if (!cities.cities) {
      await cities.getCities();
    }
  }, [location]);

  autoEffect(async () => {
    if (!streets.street) {
      await streets.getStreet(params.id);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      streets.street = null;
      cities.cities = null;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {streets.street ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              position: "sticky",
              top: 0,
              zIndex: 1,
              boxShadow: 3,
              borderRadius: 4,
            }}
          >
            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flex: 1,
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  style={{
                    overflow: "hidden",
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                  }}
                  variant="h4"
                >
                  Uredi "{streets.street.name}"
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 4,
              mx: 2,
              mb: 2,
              height: "100%",
              overflow: "auto",
            }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,

                mb: 2,
              }}
            >
              <Box sx={{ maxWidth: 600, flexGrow: 1, mx: 2 }}>
                <form>
                  <TextField
                    fullWidth
                    label="Naziv"
                    defaultValue={streets.street.name.toString()}
                    margin="normal"
                    id="name"
                    name="name"
                    type="text"
                    variant="outlined"
                    {...register("name")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.name?.message}
                  </Typography>
                  <TextField
                    fullWidth
                    label="Početni kućni broj"
                    defaultValue={streets.street.starting_number.toString()}
                    margin="normal"
                    id="starting_number"
                    name="starting_number"
                    type="text"
                    variant="outlined"
                    {...register("starting_number")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.starting_number?.message}
                  </Typography>
                  <TextField
                    fullWidth
                    label="Završni kućni broj"
                    defaultValue={streets.street.ending_number.toString()}
                    margin="normal"
                    id="ending_number"
                    name="ending_number"
                    type="text"
                    variant="outlined"
                    {...register("ending_number")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.ending_number?.message}
                  </Typography>

                  {cities.cities ? (
                    <>
                      <TextField
                        fullWidth
                        label="Grad"
                        margin="normal"
                        defaultValue={streets.street.City.id}
                        id="city_id"
                        name="city_id"
                        type="text"
                        variant="outlined"
                        {...register("city_id")}
                        select
                      >
                        <MenuItem value="" disabled>
                          Grad
                        </MenuItem>
                        {cities.cities.map((city) => (
                          <MenuItem value={city.id} key={city.id}>
                            {city.name}
                            {", "} {city.postal_code}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.city_id?.message}
                      </Typography>
                    </>
                  ) : (
                    <TextField select label="Grad" margin="normal" fullWidth>
                      <MenuItem value="" disabled>
                        Morate dodati grad
                      </MenuItem>
                    </TextField>
                  )}
                  <TextField
                    fullWidth
                    type="number"
                    onKeyPress={(e) => {
                      !/[0-9]/.test(e.key) && e.preventDefault();
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 2);
                    }}
                    label="Dan mjerenja"
                    defaultValue={streets.street.measurement_day.toString()}
                    margin="normal"
                    id="measurement_day"
                    name="measurement_day"
                    variant="outlined"
                    {...register("measurement_day")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.measurement_day?.message}
                  </Typography>
                  <Box
                    sx={{
                      py: 2,
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      style={{ backgroundColor: "#00BAFF" }}
                      fullWidth
                      disabled={streets.isLoading ? true : false}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {streets.isLoading ? "Učitavanje" : "Uredi"}
                    </Button>
                    <Button
                      style={{ backgroundColor: "gray" }}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        navigate("/backoffice/mjesta");
                      }}
                    >
                      Odustani
                    </Button>
                  </Box>
                </form>
              </Box>
            </Card>
          </Box>
        </>
      ) : null}
    </Layout>
  );
});

export default EditStreet;
