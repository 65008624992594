import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { Icon, Box } from "@mui/material";
import DeleteIconSvg from "../icons/brisi.svg";
import UsersIconSvg from "../icons/korisnici.svg";
import NotificationsIconSvg from "../icons/obavijesti.svg";
import LogoutIconSvg from "../icons/odjava.svg";
import EditIconSvg from "../icons/uredi.svg";

/// TEXTFIELD

export const useStyles = makeStyles((theme) => ({
  input: {
    background: "white",
    paddingLeft: "16px",
    borderRadius: 4,
  },
}));

/// TABLE HEADER

export const BoxSC = styled(Box)({
  display: "flex",
  alignitems: "center",
});

/// ICONS

export const IconNotificationsSC = styled(Icon)`
  &.MuiIcon-root {
    width: 12px;
    height: 16px;
    -webkit-mask: url(${NotificationsIconSvg}) no-repeat center;
    mask: url(${NotificationsIconSvg}) no-repeat center;
    background-color: #fff;
  }
`;

export const IconLogoutSC = styled(Icon)`
  &.MuiIcon-root {
    width: 16px;
    height: 16px;
    -webkit-mask: url(${LogoutIconSvg}) no-repeat center;
    mask: url(${LogoutIconSvg}) no-repeat center;
    background-color: #fff;
  }
`;

export const IconUsersSC = styled(Icon)`
  &.MuiIcon-root {
    width: 12px;
    height: 16px;
    -webkit-mask: url(${UsersIconSvg}) no-repeat center;
    mask: url(${UsersIconSvg}) no-repeat center;
    background-color: #fff;
  }
`;

export const IconDeleteSC = styled(Icon)`
  &.MuiIcon-root {
    width: 12px;
    height: 16px;
    -webkit-mask: url(${DeleteIconSvg}) no-repeat center;
    mask: url(${DeleteIconSvg}) no-repeat center;
    background-color: #00baff;
  }
`;

export const IconEditSC = styled(Icon)`
  &.MuiIcon-root {
    width: 16px;
    height: 16px;
    -webkit-mask: url(${EditIconSvg}) no-repeat center;
    mask: url(${EditIconSvg}) no-repeat center;
    background-color: #00baff;
  }
`;
