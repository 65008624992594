import { view } from "@risingstack/react-easy-state";
import { Button, Box, styled, Typography } from "@mui/material";
import { streets, cities, users } from "../../../stores/BaseStore";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { set } from "react-hook-form";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 400,
  bgcolor: "#f3f5f9",
  border: "1px solid #d3d3d3",
  borderRadius: 2,
};

const DeleteModal = view(
  ({ operation, id, open, handleClose, setDeleteSuccess }) => {
    const handleDelete = async (id) => {
      switch (operation) {
        case "ulicu":
          await streets.delete(id);
          break;
        case "mjesto":
          await cities.delete(id);
          break;

        case "korisnika":
          await users.delete(id);
          setDeleteSuccess(true);
          break;
        default:
          return null;
      }
    };

    return (
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Box
            sx={{
              paddingTop: "12px",
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "1.25rem",
                color: "#464C4C",
                marginLeft: "16px",
                marginBottom: "10px",
              }}
            >
              Potvrda
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "1rem",
                color: "rgba(0, 0, 0, 0.54)",
                marginLeft: "16px",
                marginBottom: "10px",
              }}
            >
              Jeste li sigurni da želite obrisati korisnika?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              justifyContent: "flex-end",
              marginRight: "12px",
              marginBottom: "12px",
            }}
          >
            <Button
              onClick={() => {
                handleDelete(id);
                handleClose();
              }}
              style={{ backgroundColor: "#00BAFF" }}
              variant="contained"
            >
              Da
            </Button>
            <Button
              onClick={handleClose}
              style={{ backgroundColor: "#f50057" }}
              variant="contained"
            >
              Ne
            </Button>
          </Box>
        </Box>
      </StyledModal>
    );
  }
);

export default DeleteModal;
