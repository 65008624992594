import { Fragment } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createGlobalStyle } from "styled-components";
import Wrapper from "./components/Wrapper";
import "./app.css";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

function App() {
  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />
      <Wrapper />
    </Fragment>
  );
}

export default App;
