import React, { useState, useEffect, useMemo } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useLocation } from "react-router-dom";
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loader from "components/backoffice/utils/loader";
import PlacesToolbar from "./placesToolbar";
import { IconEditSC, BoxSC } from "../../styled/styled";
import TableSortLabel from "../../utils/tableSortLabel";
import PlaceDetailModal from "components/backoffice/utils/placeDetailModal";
import { sortArray } from "./sort";

const PlacesResult = view(({ cities }) => {
  const location = useLocation();

  const [allCities, setAllCities] = useState(null);
  const [success, setSuccess] = useState([]);

  const [input, setInput] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const [openDetail, setOpenDetail] = useState(false);
  const handleOpenDetail = () => setOpenDetail(true);
  const handleCloseDetail = () => setOpenDetail(false);
  const [deleteID, setDeleteID] = useState("");

  const headers = [
    { name: "Naziv grada", field: "name", sortable: true },
    { name: "Poštanski broj", field: "postal_code", sortable: true },
  ];

  const citiesData = useMemo(() => {
    let computedCities = allCities;
    if (!computedCities) computedCities = [];

    if (input) {
      computedCities = computedCities.filter(
        (city) =>
          city.name.toLowerCase().includes(input.toLowerCase()) ||
          city.postal_code.toLowerCase().includes(input.toLowerCase())
      );
    }
    //Sorting comments
    if (sortingField) {
      sortArray(computedCities, sortingField, sortingOrder);
    }
    //Current Page slice
    return computedCities;
  }, [allCities, input, sortingField, sortingOrder]);

  const onSortingChange = (field) => {
    const order = sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
  };

  const handleID = (id) => {
    setDeleteID(id);
  };

  autoEffect(async () => {
    if (!cities.cities) {
      await cities.getCities();
      setAllCities(cities.cities);
    }
  }, [location]);

  autoEffect(async () => {
    if (success) {
      await cities.getCities();
      setAllCities(cities.cities);
      setSuccess(false);
    }
  }, [success]);

  useEffect(() => {
    return () => {
      cities.cities = null;
    };
    // eslint-disable-next-line
  }, []);

  if (citiesData.length === 0 && !input) {
    return (
      <Box>
        <PlacesToolbar input={input} setInput={setInput} disabled={true} />
        {cities.isLoading ? (
          <Loader />
        ) : (
          <Typography
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            Nema niti jednog grada
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <PlacesToolbar input={input} setInput={setInput} disabled={false} />

      {cities.cities && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            overflow: "auto",
            mb: 2,
            mx: 2,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <Table className="icon" stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map(({ name, field, sortable }) => (
                  <TableCell
                    key={field}
                    onClick={() => (sortable ? onSortingChange(field) : null)}
                  >
                    <BoxSC>
                      {name}
                      <TableSortLabel
                        sortingColumn={field}
                        sortingField={sortingField}
                        orderDirection={sortingOrder}
                      />
                    </BoxSC>
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {input && citiesData.length === 0 && (
                <Box sx={{ m: 2 }}>
                  <Typography color="textPrimary" variant="body1">
                    Nisu pronađeni rezultati.
                  </Typography>
                </Box>
              )}
              {citiesData.map((city) => (
                <TableRow hover key={city.id}>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {city.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {city.postal_code}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell style={{ width: "auto" }}>
                    <Button
                      onClick={() => {
                        handleOpenDetail();
                        handleID(city.id);
                      }}
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        color: "#004A66",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      Uredi
                      <IconEditSC />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {openDetail ? (
            <PlaceDetailModal
              open={openDetail}
              id={deleteID}
              handleClose={handleCloseDetail}
              setSuccess={setSuccess}
            />
          ) : null}
        </Card>
      )}
    </>
  );
});

export default PlacesResult;
