import React from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../../stores/BaseStore";
import { Link as RouterLink } from "react-router-dom";
import { Box, Divider, ListItem, ListItemText } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import PlaceIcon from "@mui/icons-material/Place";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "../../icons/logo.png";

const items = [
  {
    to: "/backoffice/korisnici",
    icon: <GroupIcon fontSize="small" />,
    title: "Korisnici",
  },
  {
    to: "/backoffice/mjesta",
    icon: <PlaceIcon fontSize="small" />,
    title: "Mjesta",
  },
  {
    to: "/backoffice/ulice",
    icon: <PlaceIcon fontSize="small" />,
    title: "Ulice",
  },
  {
    to: "/backoffice/dostava-ocitanja",
    icon: <LocalShippingIcon fontSize="small" />,
    title: "Očitanja",
  },
  {
    to: "/backoffice/obavijesti",
    icon: <NotificationsIcon />,
    title: "Obavijesti",
  },
  {
    to: "/backoffice/racuni",
    icon: <CreditCardIcon />,
    title: "Računi",
  },
];

const Sidebar = () => {
  const location = useLocation();
  const handleLogout = async () => {
    await auth.logout();
  };

  const content = (
    <>
      <Box sx={{ p: 3 }}>
        <a href="/backoffice/korisnici">
          <img width={30} height={30} src={Logo} alt="" />
        </a>
      </Box>
      <Divider
        sx={{
          borderColor: "#2D3748",
          mb: 2,
        }}
      />
      <Box sx={{ flexGrow: 1 }}>
        {items.map((item) => (
          <ListItem
            sx={{
              backgroundColor: location.pathname === item.to ? "#004A66" : null,
              color: location.pathname === item.to ? "#00BAFF" : "#fff",
              fontWeight:
                location.pathname === item.to ? "fontWeightBold" : null,
              display: "flex",
              px: 3,
              borderRadius: 2,
              justifyContent: "flex-start",
              textAlign: "left",
              textTransform: "none",
              width: "80%",
              mb: 2,
              ml: "auto",
              mr: "auto",
              "&:hover": {
                color: "#00BAFF",
                backgroundColor: "#004A66",
                width: "80%",
                px: 5,
              },
            }}
            button
            component={RouterLink}
            key={item.title}
            to={item.to}
            title={item.title}
          >
            {item.icon}

            <ListItemText
              style={{ marginLeft: 5 }}
              primary={item.title}
            ></ListItemText>
          </ListItem>
        ))}
        <Divider
          sx={{
            borderColor: "#2D3748",
            mb: 2,
          }}
        />
        <ListItem
          sx={{
            color: "#fff",
            fontWeight: "fontWeightBold",
            display: "flex",
            px: 3,
            borderRadius: 2,
            justifyContent: "flex-end",
            textAlign: "left",
            textTransform: "none",
            width: "80%",
            backgroundColor: "#00BAFF",
            mb: 2,
            mt: 2,
            ml: "auto",
            mr: "auto",
            "&:hover": {
              backgroundColor: "#004A66",
              width: "80%",
              px: 5,
            },
          }}
          button
          onClick={() => {
            handleLogout();
          }}
        >
          <LogoutIcon fontSize="small" />
          <ListItemText
            style={{ marginLeft: 5 }}
            primary="Odjava"
          ></ListItemText>
        </ListItem>
      </Box>
    </>
  );

  return <Box>{content}</Box>;
};

export default Sidebar;
