import React, { useState, useEffect, useMemo } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useLocation } from "react-router-dom";
import {
  Button,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loader from "components/backoffice/utils/loader";
import StreetsToolbar from "./streetsToolbar";
import { IconEditSC, BoxSC } from "../../styled/styled";
import TableSortLabel from "../../utils/tableSortLabel";
import StreetDetailModal from "components/backoffice/utils/streetDetailModal";
import { sortArray } from "./sort";

const StreetsResult = view(({ streets }) => {
  const location = useLocation();

  const [allStreets, setAllStreets] = useState(null);
  const [success, setSuccess] = useState([]);

  const [input, setInput] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const [openDetail, setOpenDetail] = useState(false);
  const handleOpenDetail = () => setOpenDetail(true);
  const handleCloseDetail = () => setOpenDetail(false);
  const [deleteID, setDeleteID] = useState("");

  const headers = [
    { name: "Naziv ulice", field: "name", sortable: true },
    { name: "Početni broj", field: "starting_number", sortable: false },
    { name: "Završni broj", field: "ending_number", sortable: false },
    { name: "Poštanski broj", field: "postal_code", sortable: true },
    { name: "Dan mjerenja", field: "measurement_day", sortable: true },
  ];

  const streetsData = useMemo(() => {
    let computedStreets = allStreets;
    if (!computedStreets) computedStreets = [];

    if (input) {
      computedStreets = computedStreets.filter(
        (street) =>
          street.name.toLowerCase().includes(input.toLowerCase()) ||
          (street.City
            ? street.City.postal_code
                .toLowerCase()
                .includes(input.toLowerCase())
            : null)
      );
    }
    //Sorting comments
    if (sortingField) {
      sortArray(computedStreets, sortingField, sortingOrder);
    }
    //Current Page slice
    return computedStreets;
  }, [allStreets, input, sortingField, sortingOrder]);

  const onSortingChange = (field) => {
    const order = sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
  };
  const handleID = (id) => {
    setDeleteID(id);
  };

  autoEffect(async () => {
    if (!streets.streets) {
      await streets.getStreets();
      setAllStreets(streets.streets);
    }
  }, [location]);

  autoEffect(async () => {
    if (success) {
      await streets.getStreets();
      setAllStreets(streets.streets);
      setSuccess(false);
    }
  }, [success]);

  useEffect(() => {
    return () => {
      streets.streets = null;
    };
    // eslint-disable-next-line
  }, []);

  if (streetsData.length === 0 && !input) {
    return (
      <Box>
        <StreetsToolbar input={input} setInput={setInput} disabled={true} />
        {streets.isLoading ? (
          <Loader />
        ) : (
          <Typography
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            Nema niti jedne ulice
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <StreetsToolbar input={input} setInput={setInput} disabled={false} />

      {streets.streets && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            overflow: "auto",
            mb: 2,
            mx: 2,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map(({ name, field, sortable }) => (
                  <TableCell
                    key={field}
                    onClick={() => (sortable ? onSortingChange(field) : null)}
                  >
                    <BoxSC>
                      {name}
                      <TableSortLabel
                        sortingColumn={field}
                        sortingField={sortingField}
                        orderDirection={sortingOrder}
                      />
                    </BoxSC>
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {input && streetsData.length === 0 && (
                <Box sx={{ m: 2 }}>
                  <Typography color="textPrimary" variant="body1">
                    Nisu pronađeni rezultati.
                  </Typography>
                </Box>
              )}
              {streetsData.map((street) => (
                <TableRow hover key={street.id}>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {street.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {street.starting_number}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {street.ending_number}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {street.City ? (
                          street.City.postal_code
                        ) : (
                          <Typography component={"span"}>
                            Odgovarajuće mjesto je obrisano
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {street.measurement_day}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell style={{ width: "auto" }}>
                    {street.City ? (
                      <Button
                        onClick={() => {
                          handleOpenDetail();
                          handleID(street.id);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          color: "#004A66",
                          gap: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Uredi
                        <IconEditSC />
                      </Button>
                    ) : (
                      <Button
                        disabled
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          color: "#004A66",
                          gap: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Uredi
                        <IconEditSC />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {openDetail ? (
            <StreetDetailModal
              open={openDetail}
              id={deleteID}
              handleClose={handleCloseDetail}
              setSuccess={setSuccess}
            />
          ) : null}
        </Card>
      )}
    </>
  );
});

export default StreetsResult;
