import React from "react";
import { view } from "@risingstack/react-easy-state";
import { bills } from "../../../../stores/BaseStore";
import Layout from "../../hoc/layout";
import BillsResult from "./billsResult";

const Bills = view(() => {
  return (
    <Layout>
      <BillsResult bills={bills} />
    </Layout>
  );
});
export default Bills;
