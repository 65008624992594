import React, { useEffect } from "react";
import Layout from "components/backoffice/hoc/layout";
import { cities } from "../../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "components/backoffice/utils/loader";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { placesSchema } from "../../schemas/index";

const EditPlace = view(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(placesSchema) });

  const onSubmit = async (data) => {
    await cities.editCity(data.name, data.postal_code, params.id);
    navigate("/backoffice/mjesta");
  };

  autoEffect(async () => {
    if (!cities.city) {
      await cities.getCity(params.id);
    }
  }, [location]);

  useEffect(() => {
    return () => (cities.city = null);
  }, []);

  return (
    <Layout>
      {cities.city ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              position: "sticky",
              top: 0,
              zIndex: 1,
              boxShadow: 3,
              borderRadius: 4,
            }}
          >
            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flex: 1,
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  style={{
                    overflow: "hidden",
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                  }}
                  variant="h4"
                >
                  Uredi "{cities.city.name}"
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 4,
              mx: 2,
              mb: 2,
              height: "100%",
              overflow: "auto",
            }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                mb: 2,
              }}
            >
              <Box sx={{ maxWidth: 500, flexGrow: 1, mx: 2 }}>
                <form>
                  <TextField
                    defaultValue={cities.city.name.toString()}
                    fullWidth
                    label="Naziv"
                    margin="normal"
                    id="name"
                    name="name"
                    type="text"
                    variant="outlined"
                    {...register("name")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.name?.message}
                  </Typography>

                  <TextField
                    fullWidth
                    type="number"
                    onKeyPress={(e) => {
                      !/[0-9]/.test(e.key) && e.preventDefault();
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 5);
                    }}
                    label="Poštanski broj"
                    defaultValue={cities.city.postal_code.toString()}
                    margin="normal"
                    id="postal_code"
                    name="postal_code"
                    variant="outlined"
                    {...register("postal_code")}
                  />
                  <Typography
                    style={{
                      marginTop: "0",
                      marginBottom: "0",
                      marginRight: "14px",
                      marginLeft: "14px",
                      fontSize: "0.75rem",
                    }}
                    color="error"
                  >
                    {errors.postal_code?.message}
                  </Typography>
                  <Box
                    sx={{
                      py: 2,
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      style={{ backgroundColor: "#00BAFF" }}
                      fullWidth
                      disabled={cities.isLoading ? true : false}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {cities.isLoading ? "Učitavanje" : "Uredi"}
                    </Button>
                    <Button
                      style={{ backgroundColor: "gray" }}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        navigate("/backoffice/mjesta");
                      }}
                    >
                      Odustani
                    </Button>
                  </Box>
                </form>
              </Box>
            </Card>
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </Layout>
  );
});

export default EditPlace;
