import React from "react";
import { view } from "@risingstack/react-easy-state";
import { cities } from "../../../../stores/BaseStore";
import Layout from "../../hoc/layout";
import PlacesResult from "./placesResult";

const Places = view(() => {
  return (
    <Layout>
      <PlacesResult cities={cities} />
    </Layout>
  );
});

export default Places;
