import { Typography } from "@mui/material";
import styled from "styled-components";

export const WrapperSC = styled.div`
  justify-content: center;
  display: flex;
  margin: 80px 16px 0 16px;
`;

export const ButtonContainerSC = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
`;

export const ForgotPasswordContainerSC = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

export const ErrorTextSC = styled(Typography)`
  &.MuiTypography-root {
    margin: 0 14px 0 14px;
    font-size: 0.75rem;
  }
`;
