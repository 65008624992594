import React from "react";
import { view } from "@risingstack/react-easy-state";
import { devices } from "../../../../stores/BaseStore";
import Layout from "../../hoc/layout";
import DeliveryResult from "./deliveryResult";

const Delivery = view(() => {
  return (
    <Layout>
      <DeliveryResult devices={devices} />
    </Layout>
  );
});

export default Delivery;
