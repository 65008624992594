import React from "react";
import { view } from "@risingstack/react-easy-state";
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../../backoffice.css";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useStyles } from "../../styled/styled";

const styles = { width: 200, display: "block" };

const DeliveryToolbar = view(
  ({ input, setInput, setStartDate, setEndDate, disabled }) => {
    const classes = useStyles();
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 2,
            position: "sticky",
            top: 0,
            zIndex: 1,
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              padding: "36px 16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <Box sx={{ flex: "40%" }}>
                <Typography style={{ fontSize: "2rem" }}>
                  DOSTAVA OČITANJA
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              padding: "0px 16px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ maxWidth: 500, flex: 1, mr: 1 }}>
              <TextField
                fullWidth
                InputProps={{
                  className: classes.input,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    padding: 8,
                  },
                }}
                style={{ padding: 0 }}
                placeholder="Pretraži očitanja"
                disabled={disabled}
                variant="outlined"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flex: 1,
                gap: "12px",
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  oneTap
                  disabled={disabled}
                  onChange={(e) => setStartDate(e)}
                  size="lg"
                  placeholder="Datum od"
                  format="dd-MM-yyyy"
                  style={styles}
                  locale={{
                    sunday: "Ned",
                    monday: "Pon",
                    tuesday: "Uto",
                    wednesday: "Sri",
                    thursday: "Čet",
                    friday: "Pet",
                    saturday: "Sub",
                    ok: "Potvrdi",
                    today: "Danas",
                    yesterday: "Jučer",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <DatePicker
                  oneTap
                  disabled={disabled}
                  placement="leftStart"
                  onChange={(e) => setEndDate(e)}
                  size="lg"
                  placeholder="Datum do"
                  format="dd-MM-yyyy"
                  style={styles}
                  locale={{
                    sunday: "Ned",
                    monday: "Pon",
                    tuesday: "Uto",
                    wednesday: "Sri",
                    thursday: "Čet",
                    friday: "Pet",
                    saturday: "Sub",
                    ok: "Potvrdi",
                    today: "Danas",
                    yesterday: "Jučer",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
);
export default DeliveryToolbar;
