import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function TableSortLabel({ orderDirection, sortingField, sortingColumn }) {
  return (
    <>
      <span
        style={{
          color:
            sortingField === sortingColumn ? "#00BAFF" : "rgba(0, 0, 0, 0.6)",
        }}
      >
        {orderDirection === "asc" ? (
          <ArrowDownwardIcon
            style={{
              marginTop: 2,
              transform: "scale(0.8)",
            }}
            fontSize="small"
          />
        ) : (
          <ArrowUpwardIcon
            style={{
              marginTop: 2,
              transform: "scale(0.8)",
            }}
            fontSize="small"
          />
        )}
      </span>
    </>
  );
}

export default TableSortLabel;
