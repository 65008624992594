import React, { useEffect, useState } from "react";

import { users } from "../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Button,
  Box,
  styled,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { useLocation } from "react-router-dom";
import Loader from "components/backoffice/utils/loader";
import AdminModal from "./adminModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usersEditSchema } from "../schemas/index";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 600,
  bgcolor: "#f3f5f9",
  border: "1px solid #d3d3d3",
  borderRadius: 2,
};

const UserDetailModal = view(({ id, open, handleClose, setSuccess }) => {
  const location = useLocation();

  const [edit, setEdit] = useState(false);

  const [openAdmin, setOpenAdmin] = useState(false);
  const handleOpenAdmin = () => setOpenAdmin(true);
  const handleCloseAdmin = () => setOpenAdmin(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(usersEditSchema) });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    oib: null,
    user_code: null,
    company: "",
    role_id: 0,
    id: null,
  });

  const handleEdit = () => {
    setEdit((prevEdit) => !prevEdit);
  };

  const onSubmit = async (data) => {
    if (!data.oib) {
      data.oib = null;
    }
    if (!data.user_code) {
      data.user_code = null;
    }

    if (data.role_id === 2) {
      await users.editUser(
        data.email,
        data.password,
        data.name,
        data.oib,
        data.user_code,
        data.company,
        data.role_id,
        id
      );
      if (users.success) {
        setSuccess(true);
        handleClose();
      }
    } else {
      handleOpenAdmin();
    }

    setFormData({
      ...formData,
      email: data.email,
      password: data.password,
      name: data.name,
      oib: data.oib,
      user_code: data.user_code,
      company: data.company,
      role_id: data.role_id,
      id: id,
    });
  };

  autoEffect(async () => {
    if (!users.user) {
      await users.getUser(id);
      await users.userDetails();
    }
  }, [location]);

  useEffect(() => {
    return () => {
      users.success = false;
      users.errors = null;
      users.user = null;
    };
    // eslint-disable-next-line
  }, []);

  const preventNonNumericalInputOib = (e) => {
    e = e || window.event;
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/) || e.target.value.length === 11) {
      e.preventDefault();
    }
  };

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      disableScrollLock
    >
      <Box sx={style}>
        {users.user ? (
          <>
            <Box
              sx={{
                paddingTop: "12px",
                mx: 2,
                mb: 2,
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "1.25rem",
                  color: "#464C4C",
                  marginBottom: "10px",
                }}
              >
                Detalji korisnika
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  color: "rgba(0, 0, 0, 0.54)",
                  marginBottom: "10px",
                }}
              >
                {users.user.name}
              </Typography>
            </Box>

            <Box
              sx={{
                boxShadow: 1,
                mx: 2,
                mb: 2,
                height: "100%",
                overflow: "auto",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  mb: 2,
                }}
              >
                <Box sx={{ maxWidth: 500, flexGrow: 1, mx: 2 }}>
                  <form>
                    <fieldset style={{ border: "none" }} disabled={!edit}>
                      <TextField
                        fullWidth
                        label="Uloga korisnika"
                        margin="normal"
                        id="role_id"
                        name="role_id"
                        type="text"
                        variant="outlined"
                        {...register("role_id")}
                        defaultValue={users.user.role_id}
                        select
                      >
                        <MenuItem value="" disabled>
                          Uloga korisnika
                        </MenuItem>
                        <MenuItem value={1}>Admin</MenuItem>
                        <MenuItem value={2}>Korisnik </MenuItem>
                        <MenuItem value={3}>Inkasator</MenuItem>
                      </TextField>
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.role_id?.message}
                      </Typography>

                      <TextField
                        defaultValue={users.user.email.toString()}
                        fullWidth
                        label="Email"
                        margin="normal"
                        id="email"
                        name="email"
                        type="text"
                        variant="outlined"
                        {...register("email")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.email?.message}
                      </Typography>

                      <TextField
                        fullWidth
                        label="Nova lozinka"
                        margin="normal"
                        id="password"
                        name="password"
                        type="password"
                        variant="outlined"
                        {...register("password")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.password?.message}
                      </Typography>

                      <TextField
                        defaultValue={users.user.name?.toString()}
                        fullWidth
                        label="Ime i prezime"
                        margin="normal"
                        id="name"
                        name="name"
                        type="text"
                        variant="outlined"
                        {...register("name")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.name?.message}
                      </Typography>

                      <TextField
                        defaultValue={
                          users.user.oib ? users.user.oib.toString() : null
                        }
                        fullWidth
                        label="OIB"
                        margin="normal"
                        id="oib"
                        name="oib"
                        type="number"
                        onKeyPress={preventNonNumericalInputOib}
                        variant="outlined"
                        {...register("oib")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.oib?.message}
                      </Typography>

                      <TextField
                        defaultValue={
                          users.user.user_code
                            ? users.user.user_code.toString()
                            : null
                        }
                        fullWidth
                        label="Šifra korisnika"
                        margin="normal"
                        id="user_code"
                        name="user_code"
                        type="text"
                        variant="outlined"
                        {...register("user_code")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.user_code?.message}
                      </Typography>

                      <TextField
                        defaultValue={
                          users.user.company
                            ? users.user.company.toString()
                            : null
                        }
                        fullWidth
                        label="Tvrtka"
                        margin="normal"
                        id="company"
                        name="company"
                        type="text"
                        variant="outlined"
                        {...register("company")}
                      />
                      <Typography
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                          marginRight: "14px",
                          marginLeft: "14px",
                          fontSize: "0.75rem",
                        }}
                        color="error"
                      >
                        {errors.company?.message}
                      </Typography>
                    </fieldset>
                  </form>
                  {openAdmin ? (
                    <AdminModal
                      setSuccess={setSuccess}
                      operation="edit"
                      openAdmin={openAdmin}
                      handleClose={handleClose}
                      handleCloseAdmin={handleCloseAdmin}
                      formData={formData}
                    />
                  ) : null}
                </Box>
              </Card>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                justifyContent: "flex-end",
                marginRight: "12px",
                marginBottom: "12px",
              }}
            >
              {edit ? (
                <Button
                  onClick={handleSubmit(onSubmit)}
                  style={{ backgroundColor: "#00BAFF" }}
                  variant="contained"
                >
                  Spremi
                </Button>
              ) : (
                <Button
                  onClick={handleEdit}
                  style={{ backgroundColor: "#00BAFF" }}
                  variant="contained"
                >
                  Uredi
                </Button>
              )}

              <Button
                onClick={handleClose}
                style={{ backgroundColor: "#f50057" }}
                variant="contained"
              >
                Odustani
              </Button>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </StyledModal>
  );
});

export default UserDetailModal;
